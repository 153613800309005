import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AuthService from 'Services/AuthService';
import UserService from 'Services/UserService';

const initialState = {
    user: {
        userId: null,
        username: '',
        email: '',
        fullName: '',
        department: { id: null, name: '' },
        laboratory: { id: null, name: '' },
        rolesWithPermissions: [],
        permissions: [],
    },
    status: 'idle',
    error: null,
};


export const fetchUserInfo = createAsyncThunk('auth/fetchUserInfo', async (_, { rejectWithValue }) => {
    try {
        const token = AuthService.getToken();
        if (token) {
            const userInfo = await UserService.getMyInfo(token);
            return userInfo;
        } else {
            return rejectWithValue('No token found');
        }
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const login = createAsyncThunk('auth/login', async ({ email, password }) => {
    const response = await AuthService.login({ email, password });
    const userInfo = await UserService.getMyInfo(response.result.token);
    return { ...response, userInfo };
});

export const logout = createAsyncThunk('auth/logout', async () => {
    await AuthService.logout();
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearUserState: (state) => {
            state.user = initialState.user;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserInfo.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const userInfo = action.payload;

                state.user = {
                    ...userInfo,
                    department: userInfo.department || { id: null, name: '' },
                    laboratory: userInfo.laboratory || { id: null, name: '' },
                };

                // Process rolesWithPermissions to extract descriptions and permissions
                state.user.rolesWithPermissions = userInfo.rolesWithPermissions.map((role) => ({
                    roleId: role.roleId,
                    roleName: role.roleName,
                    description: role.description,
                    permissions: role.permissions,
                }));

                // Flatten permissions from all roles
                state.user.permissions = userInfo.rolesWithPermissions.flatMap((role) => role.permissions);
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(login.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(login.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const userInfo = action.payload.userInfo;

                // Gán toàn bộ thông tin user, bao gồm department và laboratory
                state.user = {
                    ...userInfo,
                    department: userInfo.department || { id: null, name: '' },
                    laboratory: userInfo.laboratory || { id: null, name: '' },
                };

                // Process rolesWithPermissions to extract descriptions and permissions
                state.user.rolesWithPermissions = userInfo.rolesWithPermissions.map((role) => ({
                    roleId: role.roleId,
                    roleName: role.roleName,
                    description: role.description,
                    permissions: role.permissions,
                }));

                // Flatten permissions from all roles
                state.user.permissions = userInfo.rolesWithPermissions.flatMap((role) => role.permissions);
            })
            .addCase(login.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(logout.fulfilled, (state) => {
                state.status = 'idle';
                state.user = initialState.user;
            });
    },
});

export const { clearUserState } = authSlice.actions;

export default authSlice.reducer;
