import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Button, Input, Card, CardBody } from 'reactstrap';
import { Breadcrumbs } from 'AbstractElements';
import { toast, ToastContainer } from 'react-toastify';
import { BsFillPencilFill, BsFillTrash3Fill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PaginationComponent from 'Components/Pagination/PaginationComponent';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import UserService from 'Services/UserService';
import { FaEdit, FaTrash } from 'react-icons/fa';

const UserTable = () => {
    const { t } = useTranslation(['user', 'general', 'department', 'laboratory']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        debouncedFetchUsers(currentPage, pageSize, filterText);
        return () => {
            debouncedFetchUsers.cancel();
        };
    }, [currentPage, pageSize, filterText]);

    const fetchUsers = async (page = 0, size = 10, filterText) => {
        const response = await UserService.getUsers(page, size, filterText);
        setData(response.content);
        setTotalRecords(response.totalElements);
    };

    const debouncedFetchUsers = useCallback(
        debounce((page, size, filterText) => {
            fetchUsers(page, size, filterText);
        }, 300),
        [],
    );

    const handleDelete = (userId) => {
        setSelectedUserId(userId);
        setModal(true);
    };

    const handleEdit = (userId) => {
        navigate(`/users/edit/${userId}`);
    };

    const confirmDelete = async () => {
        try {
            await UserService.deleteUser(selectedUserId);
            setData(data.filter((user) => user.userId !== selectedUserId));
            setModal(false);
            toast.success(t('user:DeleteS'));
        } catch (error) {
            toast.error(t('user:DeleterE'));
        }
    };

    const openAddForm = () => {
        navigate('/users/create');
    };

    const toggleModal = () => setModal(!modal);

    const userTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.userId,
            sortable: true,
            width: '60px',
            center: true,
        },
        {
            name: t('general:Name'),
            selector: (row) => row.fullName,
            sortable: true,
        },
        {
            name: t('general:Username'),
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: t('department:Department'),
            selector: (row) => (row.departmentName ? row.departmentName : t('general:Unknown')),
            sortable: true,
            center: true,
        },
        {
            name: t('laboratory:Laboratory'),
            selector: (row) => (row.laboratoryName ? row.laboratoryName : t('general:Unknown')),
            sortable: true,
            center: true,
        },
        {
            name: t('general:Email'),
            selector: (row) => row.email,
            sortable: true,
            cell: (row) => <div style={{ textAlign: 'left', width: '100%' }}>{row.email}</div>,
        },
        {
            name: t('user:Role'),
            selector: (row) => (
                <div>
                    {row.roles.map((role, index) => (
                        <div key={index} style={{ display: 'block' }}>
                            {role}
                        </div>
                    ))}
                </div>
            ),
            center: true,
        },
        {
            name: t('general:Action'),
            cell: (row) => {
                const isAdmin = row.roles.includes('ADMIN');

                return (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <FaEdit
                            style={{
                                cursor: 'pointer',
                                marginRight: '10px',
                                color: '#007bff',
                                fontSize: '1.2rem',
                            }}
                            onClick={() => handleEdit(row.id)}
                        />
                        <FaTrash
                            style={{
                                cursor: 'pointer',
                                color: '#dc3545',
                                fontSize: '1.2rem',
                            }}
                            onClick={() => handleDelete(row.id)}
                        />
                    </div>
                );
            },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#f2f1fd',
                fontWeight: 'bold',
                textAlign: 'center',
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                fontSize: '14px',
                padding: '10px',
            },
        },
        cells: {
            style: {
                padding: '10px',
                fontSize: '14px',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
            },
            stripedStyle: {
                backgroundColor: '#f8f9fa',
            },
        },
    };

    return (
        <>
            <Breadcrumbs mainTitle={t('user:Manage')} parent={{ label: t('user:Manage'), url: '/Users' }} />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="4">
                                <h4>{t('user:Users')}</h4>
                            </Col>
                            <Col md="2" className="text-end">
                                <Button color="primary" className="txt-primary" onClick={openAddForm}>
                                    {t('user:Add')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="6" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('user:EnterNameUser')}
                                    className="w-auto d-inline-block"
                                    name="search"
                                    id="searchInput"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button color="primary" className="ms-2 txt-primary">
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                        <DataTable
                            data={data}
                            columns={userTableColumns}
                            striped={true}
                            center={true}
                            customStyles={customStyles}
                        />
                        <Row className="mt-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title={t('general:ConfirmDelete')}
                children={t('user:ConfirmDelete')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText={t('general:Cancel')}
                saveText={t('general:Delete')}
            ></CommonModal>
            <ToastContainer />
        </>
    );
};

export default UserTable;
