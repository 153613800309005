import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Row, Col, Card, CardBody, Form, FormGroup, Label, CardFooter, Container } from 'reactstrap';
import {
    InputKeyChemicals,
    InputNameChemicals,
    InputUnitCaculatorChemicals,
    AddChemicals,
    CancelChemical,
    TitleAddFormChemicals,
} from 'Constant';
import HeaderCard from 'Components/Common/Component/HeaderCard';
import { Btn } from 'AbstractElements';
import { Link } from 'react-router-dom';
const Create = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        if (data !== '') {
            alert('You submitted the form and stuff!');
        } else {
            errors.showMessages();
        }
    };
    return (
        <Fragment>
            <Container fluid={true}>
                <Fragment>
                    <Card>
                        <HeaderCard title={TitleAddFormChemicals} />
                        <Form
                            className="form theme-form needs-validation"
                            noValidate=""
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label" for="validationNameChemical">
                                                {InputNameChemicals}
                                                <span className="text-danger">*</span>
                                            </Label>
                                            <Col sm="9">
                                                <input
                                                    className="form-control"
                                                    id="validationNameChemical"
                                                    type="text"
                                                    placeholder="Tên hóa chất"
                                                    name="nameChemical"
                                                    {...register('nameChemical', { required: true })}
                                                />
                                                <span className="d-block" style={{ color: 'red' }}>
                                                    {errors.nameChemical && 'Bắt buộc nhập tên hóa chất'}
                                                </span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label" for="validationKeyChemical">
                                                {InputKeyChemicals}
                                            </Label>
                                            <Col sm="9">
                                                <input
                                                    className="form-control"
                                                    id="validationKeyChemical"
                                                    type="text"
                                                    placeholder="Mã hóa chất"
                                                    name="keyChemical"
                                                    {...register('keyChemical', { required: true })}
                                                />
                                                <span className="d-block" style={{ color: 'red' }}>
                                                    {errors.keyChemical && 'Bắt buộc nhập mã hóa chất'}
                                                </span>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup className="row">
                                            <Label className="col-sm-3 col-form-label" for="validationUnitChemical">
                                                {InputUnitCaculatorChemicals}
                                            </Label>
                                            <Col sm="9">
                                                <input
                                                    className="form-control"
                                                    id="validationUnitChemical"
                                                    type="text"
                                                    placeholder="Đơn vị tính"
                                                    name="unitChemical"
                                                    {...register('unitChemical', { required: true })}
                                                />
                                                <span className="d-block" style={{ color: 'red' }}>
                                                    {errors.unitChemical && 'Bắt buộc nhập đơn vị tính'}
                                                </span>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="text-start">
                                <Btn attrBtn={{ color: 'primary', className: 'm-r-5' }}>{AddChemicals}</Btn>
                                <Link to={`${process.env.PUBLIC_URL}/app/project/project-list`}>
                                    <Btn attrBtn={{ color: 'secondary' }}>{CancelChemical}</Btn>
                                </Link>
                            </CardFooter>
                        </Form>
                    </Card>
                </Fragment>
            </Container>
        </Fragment>
    );
};
export default Create;
