import React, { useCallback, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Btn, Breadcrumbs } from 'AbstractElements';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import customerService from 'Services/CustomerService';

const CreateCustomerForm = () => {
    const { t } = useTranslation(['general', 'customer']);
    const [customer, setCustomer] = useState({
        name: '',
        phone: '',
        email: '',
        identityCard: '',
        dob: '',
        gender: '',
    });
    const [initialCustomer, setInitialCustomer] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    const fetchCustomerData = useCallback(async () => {
        try {
            if (isEditMode) {
                const customerData = await customerService.getInfoCustomer(id);
                const formattedCustomerData = {
                    ...customerData,
                    dob: customerData.dob ? new Date(customerData.dob).toISOString().split('T')[0] : '',
                };
                setCustomer(formattedCustomerData);
                setInitialCustomer(formattedCustomerData);
            }
        } catch (error) {
            toast.error(t('customer:FetchDataFailMessage'));
        }
    }, [isEditMode, id, t]);

    useEffect(() => {
        fetchCustomerData();
    }, [fetchCustomerData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCustomer((prevCustomer) => ({
            ...prevCustomer,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            if (isEditMode) {
                await customerService.updateCustomer(id, customer);
                toast.success(t('customer:EditSuccessMessage'));
            } else {
                await customerService.createCustomer(customer);
                toast.success(t('customer:AddSuccessMessage'));
            }
            navigate('/customers/');
        } catch (error) {
            handleError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        navigate('/customers/');
    };

    const handleError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            if (isEditMode && initialCustomer) {
                setCustomer(initialCustomer);
            }
        } else {
            toast.error(t('customer:SaveErrorMessage'));
        }
    };

    return (
        <>
            <Breadcrumbs
                parent={{ label: t('customer:Manage'), url: '/customers' }}
                title={isEditMode ? t('customer:Edit') : t('customer:Add')}
                mainTitle={isEditMode ? t('customer:Edit') : t('customer:Add')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:Name')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('general:Name')}
                                                name="name"
                                                value={customer.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:Phone')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <BsFillTelephoneFill />
                                                </span>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="0___ ___ ___"
                                                    name="phone"
                                                    value={customer.phone}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:Email')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder={t('general:Email')}
                                                name="email"
                                                value={customer.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:IdentityCard')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('general:IdentityCard')}
                                                name="identityCard"
                                                value={customer.identityCard}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:DOB')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="date"
                                                name="dob"
                                                value={customer.dob}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:Gender')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="select"
                                                name="gender"
                                                value={customer.gender}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled>
                                                    {t('general:ChooseGender')}
                                                </option>
                                                <option value="1">{t('general:Male')}</option>
                                                <option value="2">{t('general:Female')}</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CreateCustomerForm;
