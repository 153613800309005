import React, { useState, useEffect } from 'react';
import { Table, Container, Row, Col, Button, Card, CardBody, Spinner } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Breadcrumbs } from 'AbstractElements';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import RoleService from 'Services/RoleService';
import PermissionService from 'Services/PermissionService';

const RoleManager = () => {
    const { t } = useTranslation(['general', 'role']);
    const navigate = useNavigate();
    const [roles, setRoles] = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [rolePermissions, setRolePermissions] = useState({});
    const [modal, setModal] = useState(false);
    const [selectedRoleId, setSelectedRoleId] = useState(null);
    const [loading, setLoading] = useState(false); // State for managing loading

    useEffect(() => {
        const fetchRolesAndPermissions = async () => {
            try {
                const [rolesData, permissionsData] = await Promise.all([
                    RoleService.getAllRole(),
                    PermissionService.getAllPermission(),
                ]);

                setRoles(rolesData);
                setPermissions(permissionsData);

                const initialRolePermissions = rolesData.reduce((acc, role) => {
                    acc[role.id] = role.permissions;
                    return acc;
                }, {});
                setRolePermissions(initialRolePermissions);
            } catch (error) {
                toast.error(t('general:ErrorLoadingData'));
            }
        };

        fetchRolesAndPermissions();
    }, [t]);

    const handleDelete = (id) => {
        setSelectedRoleId(id);
        setModal(true);
    };

    const handleEdit = (id) => {
        navigate(`/roles/edit/${id}`);
    };

    const confirmDelete = async () => {
        try {
            await RoleService.deleteRole(selectedRoleId);
            setRoles(roles.filter((role) => role.id !== selectedRoleId));
            toast.success(t('role:DeleteS'));
        } catch (error) {
            toast.error(t('general:ErrorDeletingRole'));
        } finally {
            setModal(false);
        }
    };

    const openAddRoleForm = () => {
        navigate('/roles/create');
    };

    const saveRoles = async () => {
        setLoading(true); // Start loading
        try {
            await Promise.all(
                roles.map((role) => {
                    const updatedPermissions = rolePermissions[role.id];
                    return RoleService.updateRole(role.id, {
                        roleName: role.roleName,
                        description: role.description,
                        permissionNames: updatedPermissions,
                    });
                }),
            );
            toast.success(t('role:RolesUpdated'));
        } catch (error) {
            toast.error(t('role:ErrorUpdatingRoles'));
        } finally {
            setLoading(false);
        }
    };

    const toggleModal = () => setModal(!modal);

    const handleCheckboxChange = (roleId, permission) => {
        setRolePermissions((prev) => {
            const newPermissions = prev[roleId].includes(permission)
                ? prev[roleId].filter((perm) => perm !== permission)
                : [...prev[roleId], permission];
            return { ...prev, [roleId]: newPermissions };
        });
    };

    return (
        <>
            <Breadcrumbs mainTitle={t('role:RoleManage')} parent={{ label: t('role:RoleManage'), url: '/roles' }} />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="4">
                                <h4>{t('role:RolesPermissionsManager')}</h4>
                            </Col>
                            <Col md="8" className="text-end">
                                <Button color="primary" className="txt-primary me-2" onClick={openAddRoleForm}>
                                    + {t('role:BtnAddRole')}
                                </Button>
                                <Button color="success" className="txt-primary" onClick={saveRoles} disabled={loading}>
                                    {loading ? <Spinner size="sm" /> : t('role:BtnSaveRoles')}
                                </Button>
                            </Col>
                        </Row>

                        {/* Responsive Table */}
                        <div
                            className="scroll-container"
                            style={{
                                overflowX: 'auto',
                                overflowY: 'auto',
                                maxHeight: '600px',
                                marginTop: '20px',
                                position: 'relative',
                            }}
                        >
                            <Table bordered hover size="sm" style={{ minWidth: 800 }}>
                                <thead>
                                    <tr>
                                        {/* Permission column */}
                                        <th
                                            className="sticky-column"
                                            style={{
                                                textAlign: 'left',
                                                minWidth: '250px',
                                                position: 'sticky',
                                                left: 0,
                                                top: 0,
                                                backgroundColor: 'white',
                                                zIndex: 1,
                                            }}
                                        >
                                            {t('general:Permission')}
                                        </th>
                                        {/* Role columns */}
                                        {roles.map((role) => (
                                            <th
                                                key={role.id}
                                                className="text-center"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    position: 'sticky',
                                                    top: 0,
                                                    backgroundColor: 'white',
                                                }}
                                            >
                                                {role.description}
                                                <div className="d-flex justify-content-center mt-1">
                                                    <FaEdit
                                                        style={{
                                                            cursor:
                                                                role.roleName === 'ADMIN' ? 'not-allowed' : 'pointer',
                                                            color: role.roleName === 'ADMIN' ? '#ccc' : '#007bff',
                                                            fontSize: '18px',
                                                        }}
                                                        onClick={() => role.roleName !== 'ADMIN' && handleEdit(role.id)}
                                                    />
                                                    <FaTrash
                                                        style={{
                                                            cursor:
                                                                role.roleName === 'ADMIN' ? 'not-allowed' : 'pointer',
                                                            color: role.roleName === 'ADMIN' ? '#ccc' : '#dc3545',
                                                            fontSize: '18px',
                                                        }}
                                                        onClick={() =>
                                                            role.roleName !== 'ADMIN' && handleDelete(role.id)
                                                        }
                                                    />
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {permissions.map((permission) => (
                                        <tr key={permission.id}>
                                            {/* Permission column content, left-aligned */}
                                            <td
                                                className="sticky-column"
                                                style={{
                                                    textAlign: 'left',
                                                    position: 'sticky',
                                                    left: 0,
                                                    backgroundColor: 'white',
                                                    zIndex: 0,
                                                }}
                                            >
                                                {permission.description}
                                            </td>
                                            {/* Role columns content, centered */}
                                            {roles.map((role) => (
                                                <td key={role.id} className="text-center">
                                                    <input
                                                        type="checkbox"
                                                        checked={rolePermissions[role.id]?.includes(
                                                            permission.permissionName,
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(role.id, permission.permissionName)
                                                        }
                                                        disabled={role.roleName === 'ADMIN'}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title={t('general:ConfirmDelete')}
                children={t('role:ConfirmDelete')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText={t('general:Cancel')}
                saveText={t('general:Delete')}
            ></CommonModal>
            <ToastContainer />
        </>
    );
};

export default RoleManager;
