const api = `${process.env.PUBLIC_URL}/api`;
export const REST_API_BASE_URL = 'https://api-adn-lims.erp-smb.com/api/v1';

const CUSTOMER = '/customers';
const PackageCategory = '/package-categories';
const Package = '/packages';
const DOCTOR = '/doctors';
const PARTNER = '/partners';
const SAMPLE = '/samples';
const LABORATORY = '/laboratories';
const ROLE = '/roles';
const PERMISSION = '/permissions';
const AUTH = '/auth';
const DEPARTMENT = '/departments';
const STATUS = '/statuses';
const USER = '/users';

export const doctorsApi = {
    CREATE_DOCTOR: `${REST_API_BASE_URL}${DOCTOR}`,
    INFO_DOCTOR: `${REST_API_BASE_URL}${DOCTOR}`,
    UPDATE_DOCTOR: `${REST_API_BASE_URL}${DOCTOR}`,
    DELETE_DOCTOR: `${REST_API_BASE_URL}${DOCTOR}`,
    GET_ALL_DOCTORS: `${REST_API_BASE_URL}${DOCTOR}`,
};

export const customersApi = {
    CREATE_CUSTOMER: `${REST_API_BASE_URL}${CUSTOMER}`,
    INFO_CUSTOMER: `${REST_API_BASE_URL}${CUSTOMER}`,
    UPDATE_CUSTOMER: `${REST_API_BASE_URL}${CUSTOMER}`,
    DELETE_CUSTOMER: `${REST_API_BASE_URL}${CUSTOMER}`,
    GET_ALL_CUSTOMERS: `${REST_API_BASE_URL}${CUSTOMER}`,
};

export const packageCategoryApi = {
    CREATE_PackageCategory: `${REST_API_BASE_URL}${PackageCategory}`,
    INFO_PackageCategory: `${REST_API_BASE_URL}${PackageCategory}`,
    UPDATE_PackageCategory: `${REST_API_BASE_URL}${PackageCategory}`,
    DELETE_PackageCategory: `${REST_API_BASE_URL}${PackageCategory}`,
    GET_ALL_PackageCategories: `${REST_API_BASE_URL}${PackageCategory}`,
    GET_ALL_PackageCategory: `${REST_API_BASE_URL}${PackageCategory}/all`,
};

export const laboratoryApi = {
    CREATE_Laboratory: `${REST_API_BASE_URL}${LABORATORY}`,
    INFO_Laboratory: `${REST_API_BASE_URL}${LABORATORY}`,
    UPDATE_Laboratory: `${REST_API_BASE_URL}${LABORATORY}`,
    DELETE_Laboratory: `${REST_API_BASE_URL}${LABORATORY}`,
    GET_ALL_Laboratories: `${REST_API_BASE_URL}${LABORATORY}`,
    GET_ALL_Laboratory: `${REST_API_BASE_URL}${LABORATORY}/all`,
};
export const packageApi = {
    CREATE_Package: `${REST_API_BASE_URL}${Package}`,
    INFO_Package: `${REST_API_BASE_URL}${Package}`,
    UPDATE_Package: `${REST_API_BASE_URL}${Package}`,
    DELETE_Package: `${REST_API_BASE_URL}${Package}`,
    GET_ALL_Packages: `${REST_API_BASE_URL}${Package}`,
    CREATE_STATUS: `${REST_API_BASE_URL}${Package}`,
    GETALLPACKAGES: `${REST_API_BASE_URL}${Package}/all`,
    GET_ALL_STATUSES_BY_PACKAGE_ID: `${REST_API_BASE_URL}${Package}`,
    GETALLSTATUS: `${REST_API_BASE_URL}${STATUS}`,
    UPDATE_STATUSES: `${REST_API_BASE_URL}${Package}`,
};
export const partnersApi = {
    CREATE_PARTNER: `${REST_API_BASE_URL}${PARTNER}`,
    INFO_PARTNER: `${REST_API_BASE_URL}${PARTNER}`,
    UPDATE_PARTNER: `${REST_API_BASE_URL}${PARTNER}`,
    DELETE_PARTNER: `${REST_API_BASE_URL}${PARTNER}`,
    GET_ALL_PARTNERS: `${REST_API_BASE_URL}${PARTNER}/search`,
};
export const samplesApi = {
    CREATE_SAMPLE: `${REST_API_BASE_URL}${SAMPLE}`,
    INFO_SAMPLE: `${REST_API_BASE_URL}${SAMPLE}`,
    UPDATE_SAMPLE: `${REST_API_BASE_URL}${SAMPLE}`,
    DELETE_SAMPLE: `${REST_API_BASE_URL}${SAMPLE}`,
    GET_ALL_SAMPLES: `${REST_API_BASE_URL}${SAMPLE}/search`,
};

export const roleApi = {
    GET_ALL_ROLES: `${REST_API_BASE_URL}${ROLE}`,
    GET_ROLE_BY_ID: `${REST_API_BASE_URL}${ROLE}`,
    CREATE_ROLE: `${REST_API_BASE_URL}${ROLE}`,
    UPDATE_ROLE: `${REST_API_BASE_URL}${ROLE}`,
    DELETE_ROLE: `${REST_API_BASE_URL}${ROLE}`,
};

export const permissionApi = {
    GET_ALL_PERMISSIONS: `${REST_API_BASE_URL}${PERMISSION}`,
};

export const userApi = {
    GET_ALL_USERS: `${REST_API_BASE_URL}${USER}`,
    CREATE_USER: `${REST_API_BASE_URL}${USER}`,
    INFO_USER: `${REST_API_BASE_URL}${USER}`,
    UPDATE_USER: `${REST_API_BASE_URL}${USER}`,
    DELETE_USER: `${REST_API_BASE_URL}${USER}`,
    GET_USER_BY_LAB_ID: `${REST_API_BASE_URL}${USER}/laboratory`,
    GET_USER_BY_DEPARTMENT_ID: `${REST_API_BASE_URL}${USER}/department`,
    GET_USERS: `${REST_API_BASE_URL}${USER}/all`,
    GET_MY_INFO: `${REST_API_BASE_URL}${USER}/my-info`,
};
export const authApi = {
    CHANGE_PASSWORD: `${REST_API_BASE_URL}/password/change`,
    FORGOT_PASSWORD: `${REST_API_BASE_URL}/password/forgot`,
    RESET_PASSWORD: `${REST_API_BASE_URL}/password/reset`,
    LOGIN: `${REST_API_BASE_URL}${AUTH}/login`,
    LOGOUT: `${REST_API_BASE_URL}${AUTH}/logout-token`,
    REFRESH_TOKEN: `${REST_API_BASE_URL}${AUTH}/refresh-token`,
    INTROSPECT: `${REST_API_BASE_URL}${AUTH}/introspect`,
};
export const departmentsApi = {
    CREATE_DEPARTMENT: `${REST_API_BASE_URL}${DEPARTMENT}`,
    INFO_DEPARTMENT: `${REST_API_BASE_URL}${DEPARTMENT}`,
    UPDATE_DEPARTMENT: `${REST_API_BASE_URL}${DEPARTMENT}`,
    DELETE_DEPARTMENT: `${REST_API_BASE_URL}${DEPARTMENT}`,
    GET_ALL_DEPARTMENTS: `${REST_API_BASE_URL}${DEPARTMENT}/all`,
    GET_ALL_DEPARTMENTS_WITH_CONDITION: `${REST_API_BASE_URL}${DEPARTMENT}/search`,
    GET_ALL_DEPARTMENTS_BY_LABID: `${REST_API_BASE_URL}${DEPARTMENT}/laboratory`,
};
export const TaskApi = `${api}/task.json`;
export const BookmarkApi = `${api}/bookmark.json`;
export const ChartistApi = `${api}/chartistData.json`;
export const ChartjsApi = `${api}/chartjsData.json`;
export const ChatMemberApi = `${api}/chatMember.json`;
export const ChatApi = `${api}/chat.chats.json`;
export const ContactApi = `${api}/contacts.json`;
export const ProductsApi = `${api}/product.json`;
export const EmailApi = `${api}/email.json`;
export const ImageLightApi = `${api}/image-light.json`;
export const BigImageApi = `${api}/image-big-light.json`;
export const MasonryApi = `${api}/masonry.json`;
export const GooglechartApi = `${api}/googleChartData.json`;
export const AllProjectApi = `${api}/allProject.json`;
export const SearchAllTabsApi = `${api}/searchAllTabs.json`;
export const TableDataApi = `${api}/tableData.json`;
export const HoverableRowsTable = `${api}/hoverableRowsTable.json`;
export const TodoApi = `${api}/todo.json`;
export const JobSearchApi = `${api}/jobSearch.json`;
export const LearningApi = `${api}/learning.json`;
export const KnowledgebaseApi = `${api}/knowledgebaseDB.json`;
export const FaqApi = `${api}/Faq.json`;
export const FileApi = `${api}/files.json`;
export const UserCardApi = `${api}/usercard.json`;
export const UserEditTableAPI = `${api}/user-edit-table.json`;
export const StickyApi = `${api}/sticky.json`;
export const TypeaheadDataApi = `${api}/typeaheadData.json`;
export const FeatherDataApi = `${api}/featherData.json`;
export const FlagIconDataApi = `${api}/flagIconData.json`;
export const FontawsomeDataApi = `${api}/fontawsomeData.json`;
export const IcoDataApi = `${api}/icoData.json`;
export const ThemifyDataApi = `${api}/themifyData.json`;
