import React, { Fragment, useEffect, useState } from 'react';
import {
    Row,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardBody,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    Container,
    CardFooter,
    CardHeader,
} from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Breadcrumbs } from 'AbstractElements';
import SampleService from 'Services/SampleService';
import { toast } from 'react-toastify';
import DoctorTable from 'Components/Pages/Doctor/DoctorTable';
import CustomerTable from 'Components/Pages/Customers/CustomerTable';
import { BsFillTelephoneFill } from 'react-icons/bs';
import PackageService from 'Services/PackageService';
import { useTranslation } from 'react-i18next';

const SampleForm = () => {
    const { t } = useTranslation(['general', 'sample']);
    const [modal, setModal] = useState(false);
    const [doctorModal, setDoctorModal] = useState(false);
    const [sample, setSample] = useState({
        barcode: '',
        sampleType: '',
        tubeType: '',
        location: '',
        samplingDate: '',
        collectionDate: '',
        doctorId: null,
        customerId: null,
        partnerId: null,
        packageId: null,
    });
    const [customerInfo, setCustomerInfo] = useState({
        customerId: '',
        customerName: '',
        phone: '',
        email: '',
        identityCard: '',
        gender: '',
        dob: '',
    });
    const [doctorInfo, setDoctorInfo] = useState({
        doctorId: '',
        doctorName: '',
        phone: '',
        email: '',
        commission: '',
        presentation: false,
        partnerId: '',
        partnerName: '',
    });
    const [initialSample, setInitialSample] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchSample = async () => {
            if (isEditMode) {
                try {
                    const sampleData = await SampleService.getSampleInfo(id);
                    console.log('Fetched sample data:', sampleData);
                    // Populate form fields with fetched data
                    setSample({
                        barcode: sampleData.barcode || '',
                        sampleType: sampleData.sampleType || '',
                        tubeType: sampleData.tubeType || '',
                        location: sampleData.location || '',
                        samplingDate: sampleData.samplingDate || '',
                        collectionDate: sampleData.collectionDate || '',
                        doctorId: sampleData.doctor?.id || null,
                        customerId: sampleData.customer?.id || null,
                        partnerId: sampleData.partner?.id || null,
                        packageId: sampleData.pack?.id || null,
                    });

                    setCustomerInfo({
                        customerId: sampleData.customer?.id || '',
                        customerName: sampleData.customer?.name || '',
                        phone: sampleData.customer?.phone || '',
                        email: sampleData.customer?.email || '',
                        identityCard: sampleData.customer?.identityCard || '',
                        gender: sampleData.customer?.gender || '',
                        dob: sampleData.customer?.dob || '',
                    });

                    setDoctorInfo({
                        doctorId: sampleData.doctor?.id || '',
                        doctorName: sampleData.doctor?.name || '',
                        phone: sampleData.doctor?.phone || '',
                        email: sampleData.doctor?.email || '',
                        commission: sampleData.doctor?.commission || '',
                        presentation: sampleData.doctor?.presentation || false,
                        partnerId: sampleData.doctor?.partner?.id || '',
                        partnerName: sampleData.doctor?.partner?.name || '',
                    });

                    setInitialSample(sampleData); // Store the original state to revert if needed
                } catch (error) {
                    console.error('Failed to fetch sample details:', error);
                }
            }
        };

        const fetchPackages = async () => {
            try {
                const response = await PackageService.getAllPackages();
                setPackages(response);
            } catch (error) {
                console.error('Failed to fetch package categories:', error);
            }
        };

        fetchSample();
        fetchPackages();
    }, [id, isEditMode]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSample((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCustomerChange = (e) => {
        const { name, value } = e.target;
        setCustomerInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleDoctorChange = (e) => {
        const { name, value, type, checked } = e.target;
        setDoctorInfo((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handlePackageChange = (e) => {
        setSample((prevState) => ({
            ...prevState,
            packageId: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Prepare the formatted sample
        const formattedSample = {
            ...sample,
            samplingDate: format(new Date(sample.samplingDate), "yyyy-MM-dd'T'HH:mm:ss"),
            collectionDate: format(new Date(sample.collectionDate), "yyyy-MM-dd'T'HH:mm:ss"),
        };

        // Log the formatted sample for debugging
        console.log('Formatted Sample to be sent:', formattedSample);

        try {
            if (isEditMode) {
                await SampleService.updateSample(id, formattedSample);
                toast.success(t('sample:EditSampleS'));
            } else {
                await SampleService.createSample(formattedSample);
                toast.success(t('sample:AddSampleS'));
            }
            navigate('/samples/');
        } catch (error) {
            if (error.response) {
                console.error('Error response:', error.response);
                toast.error(`Error: ${error.response.data.message || 'Failed to save sample. Please try again.'}`);
            } else {
                console.error('Request error:', error.message);
                toast.error(t('sample:SaveSampleE'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        navigate('/samples/');
    };

    const toggleModal = () => setModal(!modal);
    const toggleDoctorModal = () => setDoctorModal(!doctorModal);

    const onCustomerSelection = (customerId, customerName, phone, email, identityCard, dob, gender) => {
        setCustomerInfo({
            customerId,
            customerName,
            phone,
            email,
            identityCard,
            dob,
            gender,
        });
        setSample((prevState) => ({ ...prevState, customerId }));
        setModal(false);
    };

    const onDoctorSelection = (
        doctorId,
        doctorName,
        phone,
        email,
        commission,
        presentation,
        partnerId,
        partnerName,
    ) => {
        setDoctorInfo({
            doctorId,
            doctorName,
            phone,
            email,
            commission,
            presentation,
            partnerId,
            partnerName,
        });
        setSample((prevState) => ({ ...prevState, doctorId, partnerId }));
        setDoctorModal(false);
    };
    const sampleTypeOptions = [
        { id: 'blood', name: 'Máu' },
        { id: 'urine', name: 'Nước tiểu' },
    ];
    const tubeTypeOptions = [
        { id: 'tube_a', name: 'Ống A' },
        { id: 'tube_b', name: 'Ống B' },
    ];
    useEffect(() => {
        console.log(sample);
    }, [sample]);

    return (
        <Fragment>
            <Container fluid={true}>
                <Breadcrumbs
                    mainTitle={isEditMode ? t('sample:EditSample') : t('sample:AddSample')}
                    parent={{ label: t('sample:ManageSamples'), url: '/samples' }}
                    title={isEditMode ? t('sample:EditSample') : t('sample:AddSample')}
                />

                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="7">
                            <Card>
                                <CardHeader style={{ background: '#f2f1fd' }}>
                                    <h5>{t('sample:SampleInformation')}</h5>
                                </CardHeader>
                                <CardBody>
                                    <FormGroup>
                                        <Label for="samplingDate">{t('sample:SamplingDate')}</Label>
                                        <Input
                                            type="date"
                                            id="samplingDate"
                                            name="samplingDate"
                                            value={sample.samplingDate ? sample.samplingDate.split('T')[0] : ''}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="collectionDate">{t('sample:CollectionDate')}</Label>
                                        <Input
                                            type="date"
                                            id="collectionDate"
                                            name="collectionDate"
                                            value={sample.collectionDate ? sample.collectionDate.split('T')[0] : ''}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="barcode">Barcode</Label>
                                        <Input
                                            type="text"
                                            id="barcode"
                                            placeholder="Barcode"
                                            name="barcode"
                                            value={sample.barcode}
                                            onChange={handleChange}
                                            required
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="sampleType">{t('sample:SampleType')}</Label>
                                        <Input
                                            type="select"
                                            id="sampleType"
                                            name="sampleType"
                                            value={sample.sampleType || ''}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">{t('sample:ChooseSampleType')}</option>
                                            {sampleTypeOptions.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="tubeType">{t('sample:TubeType')}</Label>
                                        <Input
                                            type="select"
                                            id="tubeType"
                                            name="tubeType"
                                            value={sample.tubeType || ''}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="">{t('sample:ChooseTubeType')}</option>
                                            {tubeTypeOptions.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="location">{t('sample:Location')}</Label>
                                        <Input
                                            type="text"
                                            id="location"
                                            placeholder={t('sample:Location')}
                                            name="location"
                                            value={sample.location || ''}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="samplePackage">{t('sample:PackageType')}</Label>
                                        <Input
                                            type="select"
                                            id="samplePackage"
                                            name="samplePackage"
                                            value={sample.packageId || ''}
                                            onChange={handlePackageChange}
                                            required
                                        >
                                            <option value="" disabled>
                                                {t('sample:ChoosePackageType')}
                                            </option>
                                            {packages.map((samplePackage) => (
                                                <option key={samplePackage.id} value={samplePackage.id}>
                                                    {samplePackage.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </CardBody>
                                <CardFooter className="text-start">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        className="m-r-15 txt-primary"
                                    >
                                        {isEditMode ? t('general:BtnEdit') : t('general:BtnAdd')}
                                    </Button>
                                    <Button type="button" color="outline-secondary" onClick={handleReset}>
                                        {t('general:Cancel')}
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col md="5">
                            <Card>
                                <CardHeader style={{ background: '#f2f1fd' }}>
                                    <h5>{t('sample:CustomerInformation')}</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <Label for="searchPatient">{t('sample:searchPatient')}</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" className="d-flex align-items-center">
                                            <Button
                                                color="primary"
                                                className="m-r-15 txt-primary"
                                                onClick={toggleModal}
                                            >
                                                {t('general:Search')}
                                            </Button>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <Label for="customerId">{t('sample:customerName')}</Label>
                                        <Input
                                            type="text"
                                            id="customerId"
                                            placeholder={t('sample:customerName')}
                                            name="customerId"
                                            value={customerInfo.customerName || ''}
                                            readOnly
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="phone">{t('general:Phone')}</Label>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <BsFillTelephoneFill />
                                            </span>
                                            <Input
                                                type="text"
                                                id="phone"
                                                placeholder={t('general:Phone')}
                                                name="phone"
                                                value={customerInfo.phone || ''}
                                                readOnly
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input
                                            type="text"
                                            id="email"
                                            placeholder="Email"
                                            name="email"
                                            value={customerInfo.email || ''}
                                            readOnly
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="identityCard">{t('general:IdentityCard')}</Label>
                                        <Input
                                            type="text"
                                            id="identityCard"
                                            placeholder={t('general:IdentityCard')}
                                            name="identityCard"
                                            value={customerInfo.identityCard || ''}
                                            readOnly
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="dob">{t('general:DOB')}</Label>
                                        <Input
                                            type="date"
                                            id="dob"
                                            name="dob"
                                            value={customerInfo.dob ? customerInfo.dob.split('T')[0] : ''}
                                            readOnly
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="gender">{t('general:Gender')}</Label>
                                        <Input
                                            type="select"
                                            id="gender"
                                            name="gender"
                                            value={customerInfo.gender || ''}
                                            readOnly
                                        >
                                            <option value="" disabled>
                                                {t('general:Gender')}
                                            </option>
                                            <option value="1">{t('general:Male')}</option>
                                            <option value="2">{t('general:Female')}</option>
                                        </Input>
                                    </FormGroup>
                                </CardBody>
                            </Card>

                            <Card>
                                <CardHeader style={{ background: '#f2f1fd' }}>
                                    <h5>{t('sample:DoctorInformation')}</h5>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="8">
                                            <FormGroup>
                                                <Label for="searchDoctor">{t('sample:searchDoctor')}</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md="4" className="d-flex align-items-center">
                                            <Button
                                                color="primary"
                                                className="m-r-15 txt-primary"
                                                onClick={toggleDoctorModal}
                                            >
                                                {t('general:Search')}
                                            </Button>
                                        </Col>
                                    </Row>

                                    <FormGroup>
                                        <Label for="doctorName">{t('sample:DoctorName')}</Label>
                                        <Input
                                            type="text"
                                            id="doctorName"
                                            placeholder={t('sample:DoctorName')}
                                            name="doctorName"
                                            value={doctorInfo.doctorName || ''}
                                            readOnly
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="phone">{t('general:Phone')}</Label>
                                        <div className="input-group">
                                            <span className="input-group-text">
                                                <BsFillTelephoneFill />
                                            </span>
                                            <Input
                                                type="text"
                                                id="phone"
                                                placeholder={t('general:Phone')}
                                                name="phone"
                                                value={doctorInfo.phone || ''}
                                                readOnly
                                            />
                                        </div>
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input
                                            type="text"
                                            id="email"
                                            placeholder="Email"
                                            name="email"
                                            value={doctorInfo.email || ''}
                                            readOnly
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="commission">{t('sample:commission')}</Label>
                                        <Input
                                            type="text"
                                            placeholder={t('sample:commission')}
                                            id="commission"
                                            name="commission"
                                            value={doctorInfo.commission || ''}
                                            onChange={handleDoctorChange}
                                        />
                                    </FormGroup>
                                    <FormGroup className="row d-flex align-items-center">
                                        <Label className="col-sm-3 col-form-label" for="chk_presentation">
                                            {t('sample:Presentitive')}
                                        </Label>
                                        <Col sm="9" className="d-flex align-items-center">
                                            <Input
                                                className="checkbox_animated"
                                                id="chk_presentation"
                                                type="checkbox"
                                                name="presentation"
                                                checked={doctorInfo.presentation || false}
                                                onChange={handleDoctorChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="partner">{t('sample:partner')}</Label>
                                        <Input
                                            type="text"
                                            id="partner"
                                            placeholder={t('sample:partner')}
                                            name="partner"
                                            value={doctorInfo.partnerName || ''}
                                            readOnly
                                        />
                                    </FormGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal isOpen={modal} toggle={toggleModal} style={{ maxWidth: '70%', margin: 'auto' }}>
                        <ModalHeader toggle={toggleModal}>Tìm kiếm</ModalHeader>
                        <ModalBody>
                            <CustomerTable onCustomerSelection={onCustomerSelection} isFromSampleForm={true} />
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={doctorModal} toggle={toggleDoctorModal} style={{ maxWidth: '70%', margin: 'auto' }}>
                        <ModalHeader toggle={toggleDoctorModal}>Tìm kiếm bác sĩ</ModalHeader>
                        <ModalBody>
                            <DoctorTable onDoctorSelection={onDoctorSelection} isFromSampleForm={true} />
                        </ModalBody>
                    </Modal>
                </Form>
            </Container>
        </Fragment>
    );
};

export default SampleForm;
