import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { Btn } from 'AbstractElements';
import AuthService from 'Services/AuthService';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { logout } from 'redux/slice/authSlice';
import { useTranslation } from 'react-i18next';

const ChangePasswordForm = () => {
    const [formValues, setFormValues] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    const { t } = useTranslation(['general']);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleReset = () => {
        setFormValues({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { oldPassword, newPassword, confirmNewPassword } = formValues;

        if (newPassword !== confirmNewPassword) {
            setError('Mật khẩu mới và xác nhận mật khẩu không khớp.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await AuthService.changePassword(formValues);
            await dispatch(logout()).unwrap();

            setLoading(false);
            handleReset();
            toast.success('Thay đổi mật khẩu thành công');
            setTimeout(() => {
                navigate('/');
            }, 5000);
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <h5>{t('general:ChangePassword')}</h5>
                </CardHeader>
                <CardBody>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form className="theme-form" onSubmit={handleSubmit}>
                        <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('general:OldPassword')}</Label>
                            <Col sm="9">
                                <Input
                                    className="form-control"
                                    type="password"
                                    placeholder={t('general:OldPassword')}
                                    name="oldPassword"
                                    value={formValues.oldPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('general:NewPassword')}</Label>
                            <Col sm="9">
                                <Input
                                    className="form-control"
                                    type="password"
                                    placeholder={t('general:NewPassword')}
                                    name="newPassword"
                                    value={formValues.newPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">{t('general:ConfirmPassword')}</Label>
                            <Col sm="9">
                                <Input
                                    className="form-control"
                                    type="password"
                                    placeholder={t('general:ConfirmPassword')}
                                    name="confirmNewPassword"
                                    value={formValues.confirmNewPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <CardFooter className="text-start">
                            <Btn
                                attrBtn={{
                                    color: 'primary',
                                    className: 'm-r-15',
                                    type: 'submit',
                                    disabled: loading,
                                }}
                            >
                                {t('general:Confirm')}
                            </Btn>
                        </CardFooter>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ChangePasswordForm;
