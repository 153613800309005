import { packageApi } from 'api/urls';
import api from 'api/config';
class PackageService {
    createPackage = async (packages) => {
        try {
            const response = await api.post(`${packageApi.CREATE_Package}`, packages);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deletePackage = async (id) => {
        try {
            const response = await api.delete(`${packageApi.DELETE_Package}/${id}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updatePackage = async (id, packages) => {
        try {
            const response = await api.put(`${packageApi.UPDATE_Package}/${id}`, packages);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getPackages = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${packageApi.GET_ALL_Packages}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInforPackage = async (id) => {
        try {
            const response = await api.get(`${packageApi.INFO_Package}/${id}`);
            console.log(response.data.result);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    createStatus = async (id, statusRequest) => {
        try {
            await api.post(`${packageApi.CREATE_STATUS}/${id}/statuses`, statusRequest);
        } catch (error) {
            throw error;
        }
    };

    getAllPackages = async () => {
        try {
            const response = await api.get(`${packageApi.GETALLPACKAGES}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getAllStatus = async () => {
        try {
            const response = await api.get(`${packageApi.GETALLSTATUS}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
    getStatusesByPackageId = async (id) => {
        try {
            const response = await api.get(`${packageApi.GET_ALL_STATUSES_BY_PACKAGE_ID}/${id}/statuses`);
            return response.data?.result;
        } catch (error) {
            throw error;
        }
    };

    updateStatuses = async (id, data) => {
        try {
            const response = await api.put(`${packageApi.UPDATE_STATUSES}/${id}/statuses`, data);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new PackageService();
