import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { English, Vietnamese } from 'Constant';

const Language = () => {
    const [langdropdown, setLangdropdown] = useState(false);
    const [selected, setSelected] = useState('vn');
    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage('vn');
    }, [i18n]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setSelected(lng);
    };

    const LanguageSelection = (language) => {
        setLangdropdown(!language);
    };

    return (
        <li className="language-nav">
            <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
                <div className="current_lang">
                    <div className="lang" onClick={() => LanguageSelection(langdropdown)}>
                        <i className={`flag-icon flag-icon-${selected === 'vn' ? 'vn' : 'us'}`}></i>
                        <span className="lang-txt">{selected}</span>
                    </div>
                </div>
                <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
                    <div className="lang" onClick={() => changeLanguage('vn')}>
                        <i className="flag-icon flag-icon-vn"></i>
                        <span className="lang-txt">{Vietnamese}</span>
                    </div>
                    <div className="lang" onClick={() => changeLanguage('en')}>
                        <i className="flag-icon flag-icon-us"></i>
                        <span className="lang-txt">
                            {English}
                            <span> {'(US)'}</span>
                        </span>
                    </div>
                </div>
            </div>
        </li>
    );
};

export default Language;
