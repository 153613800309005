import { laboratoryApi } from 'api/urls';
import api from 'api/config';

class LaboratoryService {
    createLaboratory = async (laboratory) => {
        try {
            const response = await api.post(`${laboratoryApi.CREATE_Laboratory}`, laboratory);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deleteLaboratory = async (id) => {
        try {
            const response = await api.delete(`${laboratoryApi.DELETE_Laboratory}/${id}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updateLaboratory = async (id, laboratory) => {
        try {
            const response = await api.put(`${laboratoryApi.UPDATE_Laboratory}/${id}`, laboratory);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getLaboratories = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${laboratoryApi.GET_ALL_Laboratories}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInforLaboratory = async (id) => {
        try {
            const response = await api.get(`${laboratoryApi.INFO_Laboratory}/${id}`);
            console.log(response.data.result);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
    getAllLaboratories = async () => {
        try {
            const response = await api.get(`${laboratoryApi.GET_ALL_Laboratory}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new LaboratoryService();

