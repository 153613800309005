import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FileText, LogIn, User } from 'react-feather';
import man from 'assets/images/dashboard/profile.png';
import { LI, UL, Image, P } from 'AbstractElements';
import CustomizerContext from '_helper/Customizer';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/slice/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const UserHeader = () => {
    const { t } = useTranslation(['layout']);
    const history = useNavigate();
    const dispatch = useDispatch();
    const { layoutURL } = useContext(CustomizerContext);
    const { user } = useSelector((state) => state.auth);

    const Logout = async () => {
        try {
            await dispatch(logout()).unwrap();
            toast.success('Successfully logged out!');
            history(`${process.env.PUBLIC_URL}/login`);
        } catch (error) {
            toast.error('Logout failed!');
        }
    };

    const UserMenuRedirect = (redirect) => {
        history(redirect);
    };

    // Extracting descriptions from rolesWithPermissions and joining them by ', '
    const roleDescriptions = user.rolesWithPermissions?.map((role) => role.description).join(', ') || 'Unknown Role';

    return (
        <li className="profile-nav onhover-dropdown pe-0 py-0">
            <div className="media profile-media">
                <Image
                    attrImage={{
                        className: 'b-r-10 m-0',
                        src: user.profileURL || man,
                        alt: '',
                    }}
                />
                <div className="media-body">
                    <span>{user.fullName || 'Emay Walter'}</span>
                    <P attrPara={{ className: 'mb-0 font-roboto' }}>
                        {roleDescriptions} <i className="middle fa fa-angle-down"></i>
                    </P>
                </div>
            </div>
            <UL attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}>
                <LI
                    attrLI={{
                        onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/profile/${layoutURL}`),
                    }}
                >
                    <User />
                    <span>{t('layout:Account_label')} </span>
                </LI>
                <LI
                    attrLI={{
                        onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/todo-app/todo/${layoutURL}`),
                    }}
                >
                    <FileText />
                    <span>{t('layout:Taskboard_label')}</span>
                </LI>
                <LI attrLI={{ onClick: Logout }}>
                    <LogIn />
                    <span>{t('layout:LogOut_label')}</span>
                </LI>
            </UL>
            <ToastContainer />
        </li>
    );
};

export default UserHeader;
