// frontend/src/Services/AuthService.jsx
import axios from 'axios';
import Cookies from 'js-cookie';
import { authApi } from 'api/urls';
import api from 'api/config';
import CryptoJS from 'crypto-js';

const AUTH_TOKEN_KEY = 'auth';
const TOKEN_EXPIRATION_KEY = 'session-expiration';
const SECRET_KEY =
    '843567893696976453275974432697R634976R738467TR678T34865R6834R8763T478378637664538745673865783678548735687R3';

class AuthService {
    getToken() {
        const encryptedToken = Cookies.get(AUTH_TOKEN_KEY);
        if (encryptedToken) {
            const bytes = CryptoJS.AES.decrypt(encryptedToken, SECRET_KEY);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return null;
    }

    getTokenExpiration() {
        return Cookies.get(TOKEN_EXPIRATION_KEY);
    }

    setToken(token, expirationTime) {
        const encryptedToken = CryptoJS.AES.encrypt(token, SECRET_KEY).toString();
        Cookies.set(AUTH_TOKEN_KEY, encryptedToken);
        Cookies.set(TOKEN_EXPIRATION_KEY, expirationTime);
    }

    removeToken() {
        Cookies.remove(AUTH_TOKEN_KEY);
        Cookies.remove(TOKEN_EXPIRATION_KEY);
    }

    async login(loginDto) {
        const response = await axios.post(authApi.LOGIN, loginDto);
        const { token, expirationTime } = response.data.result;
        this.setToken(token, expirationTime);
        return response.data;
    }

    async logout() {
        const token = this.getToken();
        const response = await axios.post(authApi.LOGOUT, { token });
        this.removeToken();
        return response.data;
    }

    async introspectToken(token) {
        const response = await axios.post(authApi.INTROSPECT, { token });
        return response.data.result.valid;
    }

    async refreshToken() {
        const token = this.getToken();
        const response = await axios.post(authApi.REFRESH_TOKEN, { token });
        const { token: newToken, expirationTime } = response.data.result;
        this.setToken(newToken, expirationTime);
        return response.data;
    }

    async checkAndRefreshToken() {
        const token = this.getToken();
        const expirationTime = this.getTokenExpiration();

        if (!token || !expirationTime) {
            this.removeToken();
            return;
        }

        const currentTime = new Date().getTime();
        const expirationTimeMillis = new Date(expirationTime).getTime();
        const oneHourInMillis = 60 * 60 * 1000;

        // Check if the token is nearing expiration
        if (expirationTimeMillis - currentTime <= oneHourInMillis) {
            const isValid = await this.introspectToken(token);

            if (isValid) {
                if (expirationTimeMillis > currentTime) {
                    await this.refreshToken();
                } else {
                    await this.logout();
                    this.removeToken();
                }
            } else {
                await this.logout();
                this.removeToken();
            }
        }
    }

    changePassword = async (data) => {
        try {
            const response = await api.post(`${authApi.CHANGE_PASSWORD}`, data);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    // forgotPassword = async (data) => {
    //     try {
    //         const response = await axios.post(`${authApi.FORGOT_PASSWORD}`, data);
    //         return response.data.result;
    //     } catch (error) {
    //         throw error;
    //     }
    // };
    // resetPassword = async (data) => {
    //     try {
    //         const response = await axios.post(`${authApi.RESET_PASSWORD}`, data);
    //         return response.data.result;
    //     } catch (error) {
    //         throw error;
    //     }
    // };
}

export default new AuthService();
