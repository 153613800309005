import { departmentsApi } from 'api/urls';
import api from 'api/config';

class DepartmentService {
    createDepartment = async (department) => {
        try {
            const response = await api.post(departmentsApi.CREATE_DEPARTMENT, department);
            return response.data.result; // Đã sửa để trả về đối tượng DepartmentResponse
        } catch (error) {
            console.error('Error creating department:', error);
            throw error;
        }
    };

    updateDepartment = async (id, department) => {
        try {
            const { id: departmentId, ...departmentWithoutId } = department; // Đổi tên id để tránh xung đột
            const response = await api.put(`${departmentsApi.UPDATE_DEPARTMENT}/${id}`, departmentWithoutId);
            return response.data.result; // Đã sửa để trả về đối tượng DepartmentResponse
        } catch (error) {
            console.error(`Error updating department with id ${id}:`, error);
            throw error;
        }
    };

    deleteDepartment = async (id) => {
        try {
            await api.delete(`${departmentsApi.DELETE_DEPARTMENT}/${id}`);
            return; // Không cần trả về gì
        } catch (error) {
            console.error(`Error deleting department with id ${id}:`, error);
            throw error;
        }
    };

    getDepartments = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${departmentsApi.GET_ALL_DEPARTMENTS_WITH_CONDITION}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getDepartmentDetails = async (id) => {
        try {
            const response = await api.get(`${departmentsApi.INFO_DEPARTMENT}/${id}`);
            return response.data.result;
        } catch (error) {
            console.error(`Error fetching department with id ${id}:`, error);
            throw error;
        }
    };

    getAllDepartments = async () => {
        try {
            const response = await api.get(departmentsApi.GET_ALL_DEPARTMENTS);
            return response.data.result; // Đã sửa để trả về List<DepartmentResponse>
        } catch (error) {
            console.error('Error fetching all departments:', error);
            throw error;
        }
    };

    getAllDepartmentsByLabId = async (labId) => {
        try {
            const response = await api.get(`${departmentsApi.GET_ALL_DEPARTMENTS_BY_LABID}/${labId}`);
            return response.data.result;
        } catch (error) {
            console.error(`Error fetching departments by lab id ${labId}:`, error);
            throw error;
        }
    };
}

export default new DepartmentService();
