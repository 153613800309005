import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';
const PaginationComponent = ({ totalRecords, pageSize, currentPage, setCurrentPage }) => {
    const { t } = useTranslation(['general']);
    const totalPages = Math.ceil(totalRecords / pageSize);
    const maxPagesToShow = 5; // Số lượng trang tối đa sẽ hiển thị

    const createPaginationItems = () => {
        const items = [];

        const paginationRange = (start, end) => {
            for (let i = start; i <= end; i++) {
                items.push(
                    <PaginationItem active={i === currentPage} key={i}>
                        <PaginationLink onClick={() => setCurrentPage(i)}>{i + 1}</PaginationLink>
                    </PaginationItem>,
                );
            }
        };

        if (totalPages <= maxPagesToShow) {
            // Hiển thị tất cả các trang nếu số trang ít hơn hoặc bằng `maxPagesToShow`
            paginationRange(0, totalPages - 1);
        } else {
            // Hiển thị trang đầu tiên
            items.push(
                <PaginationItem active={0 === currentPage} key={0}>
                    <PaginationLink onClick={() => setCurrentPage(0)}>1</PaginationLink>
                </PaginationItem>,
            );

            // Hiển thị trang thứ 2
            items.push(
                <PaginationItem active={1 === currentPage} key={1}>
                    <PaginationLink onClick={() => setCurrentPage(1)}>2</PaginationLink>
                </PaginationItem>,
            );

            // Hiển thị trang thứ 3
            items.push(
                <PaginationItem active={2 === currentPage} key={2}>
                    <PaginationLink onClick={() => setCurrentPage(2)}>3</PaginationLink>
                </PaginationItem>,
            );

            // Nếu trang hiện tại cách xa trang đầu (lớn hơn 3), hiển thị dấu "..."
            if (currentPage > 3) {
                items.push(
                    <PaginationItem disabled key="start-ellipsis">
                        <PaginationLink>...</PaginationLink>
                    </PaginationItem>,
                );
            }

            // Hiển thị các trang xung quanh trang hiện tại
            const startPage = Math.max(3, currentPage - 1);
            const endPage = Math.min(totalPages - 2, currentPage + 1);
            paginationRange(startPage, endPage);

            // Nếu trang hiện tại cách xa trang cuối, hiển thị dấu "..."
            if (currentPage < totalPages - 3) {
                items.push(
                    <PaginationItem disabled key="end-ellipsis">
                        <PaginationLink>...</PaginationLink>
                    </PaginationItem>,
                );
            }

            // Hiển thị trang cuối cùng
            items.push(
                <PaginationItem active={totalPages - 1 === currentPage} key={totalPages - 1}>
                    <PaginationLink onClick={() => setCurrentPage(totalPages - 1)}>{totalPages}</PaginationLink>
                </PaginationItem>,
            );
        }

        return items;
    };

    return (
        <Pagination aria-label="Page navigation">
            <PaginationItem disabled={currentPage === 0}>
                <PaginationLink previous onClick={() => setCurrentPage(currentPage - 1)}>
                    {t('general:Previous')}
                </PaginationLink>
            </PaginationItem>

            {createPaginationItems()}

            <PaginationItem disabled={currentPage === totalPages - 1}>
                <PaginationLink next onClick={() => setCurrentPage(currentPage + 1)}>
                    {t('general:Next')}
                </PaginationLink>
            </PaginationItem>
        </Pagination>
    );
};

export default PaginationComponent;
