import api from 'api/config';
import { userApi } from 'api/urls';

class UserService {
    // Fetch user information using the token
    async getMyInfo() {
        const response = await api.get(userApi.GET_MY_INFO);
        return response.data.result;
    }

    createUser = async (user) => {
        try {
            const response = await api.post(`${userApi.CREATE_USER}`, user);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deleteUser = async (userId) => {
        try {
            const response = await api.delete(`${userApi.DELETE_USER}/${userId}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updateUser = async (userId, user) => {
        try {
            const response = await api.put(`${userApi.UPDATE_USER}/${userId}`, user);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getUsers = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${userApi.GET_ALL_USERS}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInforUser = async (userId) => {
        try {
            const response = await api.get(`${userApi.INFO_USER}/${userId}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    // Add getUserByLabId method
    getUserByLabId = async (labId) => {
        try {
            const response = await api.get(`${userApi.GET_USER_BY_LAB_ID}/${labId}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    // Add getUserByDepartmentId method
    getUserByDepartmentId = async (departmentId) => {
        try {
            const response = await api.get(`${userApi.GET_USER_BY_DEPARTMENT_ID}/${departmentId}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getAllUsers = async () => {
        try {
            const response = await api.get(`${userApi.GET_USERS}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new UserService();
