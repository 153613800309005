import React, { Fragment, useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, Input, FormGroup, Label, Button } from 'reactstrap';
import { Breadcrumbs, Btn } from 'AbstractElements';
import HeaderCard from 'Components/Common/Component/HeaderCard';
import { toast, ToastContainer } from 'react-toastify';
import { BsFillPencilFill, BsFillTrash3Fill } from 'react-icons/bs';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import PackageService from 'Services/PackageService';
import PackageCategoryService from 'Services/PackageCategoryService';
import { FaEdit, FaTrash } from 'react-icons/fa';

import { useTranslation } from 'react-i18next';
import PaginationComponent from 'Components/Pagination/PaginationComponent';

const PackagesTable = () => {
    const { t } = useTranslation(['general', 'pack']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedPackageId, setSelectedPackageId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [packageCategories, setPackageCategories] = useState([]);

    useEffect(() => {
        debouncedFetchPackages(currentPage, pageSize, filterText);
        fetchPackageCateories();
    }, [currentPage, pageSize, filterText]);

    const fetchPackages = async (page = 0, size = 10, filterText) => {
        try {
            const response = await PackageService.getPackages(page, size, filterText);
            setData(response.content);
            setTotalRecords(response.totalElements);
        } catch (error) {
            console.error('Failed to fetch packages:', error);
        }
    };

    const fetchPackageCateories = async () => {
        try {
            const response = await PackageCategoryService.getPackageCategorys(0, 10, null);
            setPackageCategories(response.content);
        } catch (error) {
            toast.error(t('pack:FetchDataPackageE'));
        }
    };

    const debouncedFetchPackages = useCallback(
        debounce((page, size, filterText) => {
            fetchPackages(page, size, filterText);
        }, 300),
        [],
    );

    const getPackageCategoryName = (categoryPackageId) => {
        const packageCategory = packageCategories.find((p) => p.id === categoryPackageId);
        return packageCategory ? packageCategory.name : 'Unknown';
    };

    const handleDelete = (id) => {
        setSelectedPackageId(id);
        setModal(true);
    };

    const handleEdit = (id) => {
        navigate(`/packages/edit/${id}`);
    };

    const confirmDelete = async () => {
        try {
            await PackageService.deletePackage(selectedPackageId);
            setData(data.filter((pkg) => pkg.id !== selectedPackageId));
            setModal(false);
            toast.success(t('pack:DeletePakageS'));
        } catch (error) {
            console.error('Failed to delete package :', error);
            toast.error(t('pack:DeletePackageE'));
        }
    };

    const openAddForm = () => {
        navigate('/packages/create');
    };

    const toggleModal = () => setModal(!modal);

    const packageTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            width: '100px',
        },
        {
            name: t('pack:PackageName'),
            selector: (row) => row.name,
            sortable: true,
            width: '400px',
        },
        {
            name: t('pack:Cost'),
            selector: (row) => row.cost,
            sortable: true,
        },
        {
            name: t('pack:PackageCategory'),
            selector: (row) => getPackageCategoryName(row.categoryPackageId),
            sortable: false,
        },
        {
            name: t('general:Action'),
            cell: (row) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <FaEdit
                        style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            color: '#007bff',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleEdit(row.id)}
                    />
                    <FaTrash
                        style={{
                            cursor: 'pointer',
                            color: '#dc3545',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleDelete(row.id)}
                    />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        },
    ];

    return (
        <>
            <Breadcrumbs
                mainTitle={t('pack:ManagePackages')}
                parent={{ label: t('pack:ManagePackages'), url: '/packages' }}
            />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="4">
                                <h4>{t('pack:Packages')}</h4>
                            </Col>
                            <Col md="4" className="text-end">
                                <Button color="primary" className="txt-primary" onClick={openAddForm}>
                                    {t('pack:AddPackage')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="6" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('general:EnterNameOrAddress')}
                                    className="w-auto d-inline-block"
                                    name="search"
                                    id="searchInput"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button
                                    color="primary"
                                    className="ms-2 txt-primary"
                                    onClick={() => debouncedFetchPackages(currentPage, pageSize, filterText)}
                                >
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {' '}
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                        <DataTable data={data} columns={packageTableColumns} striped={true} center={true} />
                        <Row className="mt-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title="Xác nhận xóa"
                children={t('pack:ConfirmDeleteMessagePackage')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText="Hủy"
                saveText="Xóa"
            ></CommonModal>
            <ToastContainer />
        </>
    );
};

export default PackagesTable;
