import React, { useCallback, useEffect, useState } from 'react';
import SampleService from '../../../Services/SampleService';
import { toast, ToastContainer } from 'react-toastify';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Breadcrumbs } from '../../../AbstractElements';
import { Button, Card, CardBody, Col, Container, Input, Row } from 'reactstrap';
import PaginationComponent from '../../Pagination/PaginationComponent';
import DataTable from 'react-data-table-component';
import CommonModal from '../../UiKits/Modals/common/modal';
import SampleSelectedView from './SampleSelectedView';

const SampleList = () => {
    const { t } = useTranslation(['general', 'sample']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedSampleId, setSelectedSampleId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedSample, setSelectedSample] = useState();

    useEffect(() => {
        debouncedFetchSamples(currentPage, pageSize, filterText);
        return () => {
            debouncedFetchSamples.cancel();
        };
    }, [currentPage, pageSize, filterText]);

    const fetchSamples = async (page = 0, size = 10, filterText) => {
        try {
            const response = await SampleService.getSamples(page, size, filterText);
            setData(response.content);
            setTotalRecords(response.totalElements);
        } catch (error) {
            toast.error(t('sample:FetchDataSampleE'));
        }
    };

    const debouncedFetchSamples = useCallback(
        debounce((page, size, filterText) => {
            fetchSamples(page, size, filterText);
        }, 300),
        [],
    );

    const handleDelete = (id) => {
        setSelectedSampleId(id);
        setModal(true);
    };

    const handleEdit = (sampleId) => {
        navigate(`/samples/edit/${sampleId}`);
    };

    const confirmDelete = async () => {
        try {
            await SampleService.deleteSample(selectedSampleId);
            setData(data.filter((sample) => sample.id !== selectedSampleId));
            setModal(false);
            toast.success(t('sample:DeleteSampleS'));
        } catch (error) {
            toast.error(t('sample:DeleteSampleE'));
        }
    };

    const openAddForm = () => {
        navigate('/samples/create');
    };

    const toggleModal = () => setModal(!modal);

    const onSampleClick = (sample) => {
        setSelectedSample(sample);
    };

    return (
        <>
            <Breadcrumbs
                mainTitle={t('sample:ManageSamples')}
                parent={{ label: t('sample:ManageSamples'), url: '/samples' }}
            />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="4">
                                <h4>{t('sample:ManageSamples')}</h4>
                            </Col>
                            <Col md="2" className="text-end">
                                <Button color="primary" className="txt-primary" onClick={openAddForm}>
                                    + {t('sample:BtnAddSample')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="4">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="4" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('sample:EnterBarcodeOrSampleType')}
                                    className="w-auto d-inline-block"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button color="primary" className="ms-2 txt-primary">
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col
                                md={selectedSample ? 4 : 12} // Adjust width based on selection
                                className="align-items-center"
                                style={{ border: '1px solid #ccc', padding: '10px' }}
                            >
                                <div className="me-3" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <div style={{ marginBottom: '20px' }}>
                                    <PaginationComponent
                                        totalRecords={totalRecords}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                </div>
                                <div>
                                    {data.map((sample) => (
                                        <div
                                            onClick={() => {
                                                setSelectedSample((prev) => (prev?.id === sample.id ? null : sample));
                                            }}
                                            key={sample.id}
                                            style={{
                                                padding: '10px',
                                                border: '1px solid #ddd',
                                                borderRadius: '5px',
                                                marginBottom: '8px',
                                                backgroundColor:
                                                    selectedSample && selectedSample.id === sample.id
                                                        ? '#cfe2ff'
                                                        : '#f9f9f9',
                                                cursor: 'pointer',
                                                transition: 'background-color 0.3s ease',
                                            }}
                                            onMouseEnter={(e) => {
                                                if (!(selectedSample && selectedSample.id === sample.id)) {
                                                    e.currentTarget.style.backgroundColor = '#e6f7ff';
                                                }
                                            }}
                                            onMouseLeave={(e) => {
                                                if (!(selectedSample && selectedSample.id === sample.id)) {
                                                    e.currentTarget.style.backgroundColor = '#f9f9f9';
                                                }
                                            }}
                                        >
                                            <Container
                                                fluid={true}
                                                style={{
                                                    padding: '20x',
                                                    backgroundColor: '#f8f9fa',
                                                    borderRadius: '8px',
                                                }}
                                            >
                                                <Row className="mb-3">
                                                    <Col md="12">
                                                        <h4>{sample?.barcode}</h4>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={6} md={12} xs={12} className="mb-4">
                                                        <div>
                                                            <span>Customer Name: </span>
                                                            <span>{sample?.customer.name}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} md={12} xs={12}>
                                                        <div>
                                                            <span>Status Statement: </span>
                                                            <span>{sample?.statusStatement}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col lg={6} md={12} xs={12} className="">
                                                        <div>
                                                            <span>Sampling Date: </span>
                                                            <span>{sample?.samplingDate}</span>
                                                        </div>
                                                    </Col>

                                                    <Col lg={6} md={12} xs={12}>
                                                        <div>
                                                            <span>Collection Date: </span>
                                                            <span>{sample?.collectionDate}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                            {selectedSample && (
                                <Col
                                    lg={8}
                                    md={8}
                                    style={{
                                        border: '1px solid #ccc',
                                        position: 'sticky',
                                        top: '80px',
                                        alignSelf: 'flex-start',
                                        backgroundColor: '#fff',
                                        zIndex: 10,
                                    }}
                                >
                                    <SampleSelectedView sample={selectedSample} />
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title={t('general:ConfirmDelete')}
                children={t('sample:ConfirmDeleteMessageSample')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText={t('general:Cancel')}
                saveText={t('general:Delete')}
            />
            <ToastContainer />
        </>
    );
};

export default SampleList;
