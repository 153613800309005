import { partnersApi } from '../api/urls';
import api from 'api/config';

class PartnerService {
    createPartner = async (partner) => {
        try {
            const response = await api.post(`${partnersApi.CREATE_PARTNER}`, partner);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deletePartner = async (id) => {
        try {
            const response = await api.delete(`${partnersApi.DELETE_PARTNER}/${id}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updatePartner = async (id, partner) => {
        try {
            const { id, ...partnerWithoutId } = partner;
            const response = await api.put(`${partnersApi.UPDATE_PARTNER}/${id}`, partnerWithoutId);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getPartners = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${partnersApi.GET_ALL_PARTNERS}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInforPartner = async (id) => {
        try {
            const response = await api.get(`${partnersApi.INFO_PARTNER}/${id}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new PartnerService();
