import React, { useCallback, Fragment, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import Select from 'react-select';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Btn, Breadcrumbs } from 'AbstractElements';
import { toast } from 'react-toastify';
import PackageService from 'Services/PackageService';
import PackageCategoryService from 'Services/PackageCategoryService';
import { useTranslation } from 'react-i18next';

const PackagesForm = () => {
    const { t } = useTranslation(['general', 'pack']);
    const [packages, setPackages] = useState({
        name: '',
        cost: '',
        categoryPackageId: null,
    });

    const [loading, setLoading] = useState(false);
    const [packageCategories, setPackageCategories] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);
    const [initialPackage, setInitialPackage] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            const [packageCategoryData, packageData] = await Promise.all([
                PackageCategoryService.getPackageCategorys(0, 10, null),
                isEditMode ? PackageService.getInforPackage(id) : Promise.resolve(null),
            ]);

            const packageCategoryOptions = packageCategoryData.content.map((packageCategory) => ({
                value: packageCategory.id,
                label: packageCategory.name,
            }));

            setPackageCategories([{ value: null, label: t('general:None') }, ...packageCategoryOptions]);

            if (isEditMode && packageData) {
                setPackages({
                    ...packageData,
                    categoryPackageId: packageData.categoryPackageId,
                });
                setInitialPackage(packageData);
            }
        } catch (error) {
            toast.error(t('general:FetchDataError'));
        }
    }, [isEditMode, id, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPackages({ ...packages, [name]: value });
    };

    const handleCategoryChange = (selectedOption) => {
        setPackages((prevPackage) => ({
            ...prevPackage,
            categoryPackageId: selectedOption.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!packages.categoryPackageId) {
            toast.error(t('pack:CategoryRequired'));
            setLoading(false);
            return;
        }

        const formattedPackages = {
            name: packages.name,
            cost: packages.cost,
            categoryPackageId: packages.categoryPackageId,
        };

        try {
            if (isEditMode) {
                await PackageService.updatePackage(id, formattedPackages);
                toast.success(t('pack:EditPackageSuccess'));
            } else {
                await PackageService.createPackage(formattedPackages);
                toast.success(t('pack:AddPackageSuccess'));
            }
            navigate('/packages/');
        } catch (error) {
            handleError(error);
        } finally {
            setLoading(false);
        }
    };

    const handleError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            if (isEditMode && initialPackage) {
                setPackages(initialPackage);
            }
        } else {
            toast.error(t('general:SaveFailed'));
        }
    };

    const handleReset = () => {
        navigate('/packages/');
    };

    return (
        <>
            <Breadcrumbs
                parent={<Link to={`${process.env.PUBLIC_URL}/packages`}>{t('pack:ManagePackages')}</Link>}
                title={isEditMode ? t('pack:EditPackage') : t('pack:AddPackage')}
                mainTitle={isEditMode ? t('pack:EditPackage') : t('pack:AddPackage')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('pack:EnterPackageName')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('pack:EnterPackageName')}
                                                name="name"
                                                value={packages.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('pack:Cost')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="number"
                                                placeholder={t('pack:EnterCost')}
                                                name="cost"
                                                value={packages.cost}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('pack:PackageCategory')}</Label>
                                        <Col sm="9">
                                            <div className="mb-2">
                                                <Select
                                                    options={packageCategories}
                                                    value={packageCategories.find(
                                                        (option) => option.value === packages.categoryPackageId,
                                                    )}
                                                    onChange={handleCategoryChange}
                                                    className="js-example-basic-single col-sm-12"
                                                    classNamePrefix="select"
                                                    placeholder={t('pack:SelectPackageCategory')}
                                                />
                                            </div>
                                        </Col>
                                    </FormGroup>

                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PackagesForm;
