import { packageCategoryApi } from 'api/urls';
import api from 'api/config';
class PackageCategoryService {
    createPackageCategory = async (packageCategory) => {
        try {
            const response = await api.post(`${packageCategoryApi.CREATE_PackageCategory}`, packageCategory);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deletePackageCategory = async (id) => {
        try {
            const response = await api.delete(`${packageCategoryApi.DELETE_PackageCategory}/${id}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updatePackageCategory = async (id, packageCategory) => {
        try {
            const response = await api.put(`${packageCategoryApi.UPDATE_PackageCategory}/${id}`, packageCategory);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getPackageCategorys = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${packageCategoryApi.GET_ALL_PackageCategories}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInforPackageCategory = async (id) => {
        try {
            const response = await api.get(`${packageCategoryApi.INFO_PackageCategory}/${id}`);
            console.log(response.data.result);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
    getAllPackageCategories = async () => {
        try {
            const response = await api.get(`${packageCategoryApi.GET_ALL_PackageCategory}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new PackageCategoryService();
