import { customersApi } from 'api/urls';
import api from 'api/config';

class CustomerService {
    createCustomer = async (customer) => {
        try {
            const response = await api.post(`${customersApi.CREATE_CUSTOMER}`, customer);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deleteCustomer = async (id) => {
        try {
            const response = await api.delete(`${customersApi.DELETE_CUSTOMER}/${id}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updateCustomer = async (id, customer) => {
        try {
            const { id, ...customerWithoutId } = customer;
            const response = await api.put(`${customersApi.UPDATE_CUSTOMER}/${id}`, customerWithoutId);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getCustomers = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${customersApi.GET_ALL_CUSTOMERS}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInfoCustomer = async (id) => {
        try {
            const response = await api.get(`${customersApi.INFO_CUSTOMER}/${id}`);
            console.log(response.data.result);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}
const customerService = new CustomerService();
export default customerService;
