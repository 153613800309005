import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Button, Input, Card, CardBody } from 'reactstrap';
import { Breadcrumbs, Btn } from 'AbstractElements';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import customerService from 'Services/CustomerService';
import { FaEdit, FaTrash } from 'react-icons/fa';
import PaginationComponent from 'Components/Pagination/PaginationComponent';

const CustomerTable = ({ onCustomerSelection, isFromSampleForm }) => {
    const { t } = useTranslation(['general', 'customer']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        debouncedFetchCustomers(currentPage, pageSize, filterText);
        return () => {
            debouncedFetchCustomers.cancel();
        };
    }, [currentPage, pageSize, filterText]);

    const fetchCustomers = async (page = 0, size = 10, filterText) => {
        try {
            const response = await customerService.getCustomers(page, size, filterText);
            setData(response.content);
            setTotalRecords(response.totalElements);
        } catch (error) {
            toast.error(t('customer:FetchDataCustomerError'));
        }
    };

    const debouncedFetchCustomers = useCallback(
        debounce((page, size, filterText) => {
            fetchCustomers(page, size, filterText);
        }, 300),
        [],
    );

    const handleDelete = (id) => {
        setSelectedCustomerId(id);
        setModal(true);
    };

    const handleEdit = (id) => {
        navigate(`/customers/edit/${id}`);
    };

    const confirmDelete = async () => {
        try {
            await customerService.deleteCustomer(selectedCustomerId);
            setData(data.filter((customer) => customer.id !== selectedCustomerId));
            setModal(false);
            toast.success(t('customer:DeleteSuccessMessage'));
        } catch (error) {
            toast.error(t('customer:DeleteErrorMessage'));
        }
    };

    const openAddForm = () => {
        if (isFromSampleForm) {
            window.open('/customers/create', '_blank');
        } else {
            navigate('/customers/create');
        }
    };

    const toggleModal = () => setModal(!modal);
    const handleRowClick = (row) => {
        console.log('Customer Selected:', row);
        onCustomerSelection(row.id, row.name, row.phone, row.email, row.identityCard, row.dob, row.gender);
    };

    const customerTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            width: '80px',
        },
        {
            name: t('general:Name'),
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => <div className="txt-secondary">{row.name}</div>,
        },
        {
            name: t('general:Phone'),
            selector: (row) => row.phone,
            sortable: true,
        },
        {
            name: t('general:Email'),
            selector: (row) => row.email,
            sortable: true,
            wrap: true,
            cell: (row) => <div style={{ textAlign: 'left', width: '100%' }}>{row.email}</div>,
        },
        {
            name: t('general:IdentityCard'),
            selector: (row) => row.identityCard,
            sortable: true,
        },
        {
            name: t('general:DOB'),
            selector: (row) => new Date(row.dob).toLocaleDateString(),
            sortable: true,
        },
        {
            name: t('general:Gender'),
            selector: (row) => (row.gender === 1 ? t('general:Male') : t('general:Female')),
            sortable: true,
            maxWidth: '100px',
        },
        {
            name: t('general:Action'),
            cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FaEdit
                        style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            color: '#007bff',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleEdit(row.id)}
                    />
                    <FaTrash
                        style={{
                            cursor: 'pointer',
                            color: '#dc3545',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleDelete(row.id)}
                    />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#f2f1fd',
                fontWeight: 'bold',
                textAlign: 'center',
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                fontSize: '14px',
                padding: '10px',
                color: '#7366FF',
            },
        },
        cells: {
            style: {
                padding: '10px',
                fontSize: '14px',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
            },
            stripedStyle: {
                backgroundColor: '#f8f9fa',
            },
        },
    };

    return (
        <>
            <Breadcrumbs mainTitle={t('customer:Manage')} parent={{ label: t('customer:Manage'), url: '/customers' }} />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="4">
                                <h4>{t('customer:Customers')}</h4>
                            </Col>
                            <Col md="3" className="text-end">
                                <Button color="primary" className="txt-primary" onClick={openAddForm}>
                                    + {t('customer:Add')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="6" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('general:EnterNameOrAddress')}
                                    className="w-auto d-inline-block"
                                    name="search"
                                    id="searchInput"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button color="primary" className="ms-2 txt-primary">
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                        <DataTable
                            data={data}
                            columns={customerTableColumns}
                            striped={true}
                            center={true}
                            customStyles={customStyles}
                            onRowClicked={handleRowClick}
                        />
                        <Row className="mt-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title={t('customer:ConfirmDeleteMessage')}
                children={t('customer:ConfirmDeleteMessage')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText={t('general:Cancel')}
                saveText={t('general:Delete')}
            ></CommonModal>
            <ToastContainer />
        </>
    );
};

export default CustomerTable;
