import { doctorsApi } from 'api/urls';
import api from 'api/config';

class DoctorService {
    createDoctor = async (doctor) => {
        try {
            const response = await api.post(`${doctorsApi.CREATE_DOCTOR}`, doctor);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deleteDoctor = async (id) => {
        try {
            const response = await api.delete(`${doctorsApi.DELETE_DOCTOR}/${id}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updateDoctor = async (id, doctor) => {
        try {
            const response = await api.put(`${doctorsApi.UPDATE_DOCTOR}/${id}`, doctor);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getDoctors = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}&sort=id,desc`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${doctorsApi.GET_ALL_DOCTORS}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getInforDoctor = async (id) => {
        try {
            const response = await api.get(`${doctorsApi.INFO_DOCTOR}/${id}`);
            console.log(response.data.result);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new DoctorService();
