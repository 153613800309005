import Signin from 'Components/Pages/Auth/Signin';
import Error403 from 'Components/Pages/ErrorPages/ErrorPage403';
import Error401 from 'Components/Pages/ErrorPages/ErrorPage401';
import Error500 from 'Components/Pages/ErrorPages/ErrorPage500';

export const publicRoutes = [
    {
        path: `${process.env.PUBLIC_URL}/login`,
        Component: <Signin />,
    },
    {
        path: `${process.env.PUBLIC_URL}/unauthorized`,
        Component: <Error403 />,
    },
    {
        path: `${process.env.PUBLIC_URL}/unauthenticated`,
        Component: <Error401 />,
    },
    {
        path: `${process.env.PUBLIC_URL}/uncategorized-error`,
        Component: <Error500 />,
    },
];
