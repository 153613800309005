import React, { Fragment, useContext } from 'react';
import sad from 'assets/images/other-images/sad.png';
import { Link } from 'react-router-dom';
import { Container, Button, Media, Col } from 'reactstrap';
import CustomizerContext from '_helper/Customizer';
import { P, H2 } from 'AbstractElements';
import { useTranslation } from 'react-i18next';

const Error500 = () => {
    const { layoutURL } = useContext(CustomizerContext);
    const { t } = useTranslation(['errorPage']);

    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="error-wrapper">
                    <Container>
                        <Media body className="img-100" src={sad} alt="" />
                        <div className="error-heading">
                            <H2 attrH2={{ className: 'headline font-primary' }}>{'500'}</H2>
                        </div>
                        <Col md="8 offset-md-2">
                            <P attrPara={{ className: 'sub-content' }}>{t('errorPage:Error500Message')}</P>
                        </Col>
                        <Link to={`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`}>
                            <Button color="primary-gradien" size="lg">
                                {t('errorPage:BackToHomePageButton')}
                            </Button>
                        </Link>
                    </Container>
                </div>
            </div>
        </Fragment>
    );
};

export default Error500;
