import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Button, Input, Card, CardBody } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Breadcrumbs, Btn } from 'AbstractElements';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PaginationComponent from 'Components/Pagination/PaginationComponent';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import DoctorService from 'Services/DoctorService';
import { AlignJustify, AlignRight } from 'react-feather';

const DoctorTable = ({ onDoctorSelection, isFromSampleForm }) => {
    const { t } = useTranslation(['general', 'doctor', 'partner']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedDoctorId, setSelectedDoctorId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        debouncedFetchDoctors(currentPage, pageSize, filterText);
        return () => {
            debouncedFetchDoctors.cancel();
        };
    }, [currentPage, pageSize, filterText]);

    const fetchDoctors = async (page = 0, size = 10, filterText) => {
        const response = await DoctorService.getDoctors(page, size, filterText);
        setData(response.content);
        setTotalRecords(response.totalElements);
    };

    const debouncedFetchDoctors = useCallback(
        debounce((page, size, filterText) => {
            fetchDoctors(page, size, filterText);
        }, 300),
        [fetchDoctors],
    );

    const handleDelete = (id) => {
        setSelectedDoctorId(id);
        setModal(true);
    };

    const handleEdit = (id) => {
        navigate(`/doctors/edit/${id}`);
    };

    const confirmDelete = async () => {
        try {
            await DoctorService.deleteDoctor(selectedDoctorId);
            setData(data.filter((doctor) => doctor.id !== selectedDoctorId));
            setModal(false);
            toast.success(t('doctor:DeleteS'));
        } catch (error) {
            toast.error(t('doctor:DeleteE'));
        }
    };

    const openAddForm = () => {
        if (isFromSampleForm) {
            window.open('/doctors/create', '_blank');
        } else {
            navigate('/doctors/create');
        }
    };

    const toggleModal = () => setModal(!modal);
    const handleRowClick = (row) => {
        if (typeof onDoctorSelection === 'function') {
            onDoctorSelection(
                row.id, // doctorId
                row.name, // doctorName
                row.phone, // phone
                row.email, // email
                row.commission, // commission
                row.presentation, // presentation
                row.partnerId, // pass the correct partnerId
                row.partnerName, // pass the correct partnerName
            );
        }
    };

    const doctorTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            width: '60px',
            center: true,
        },
        {
            name: t('general:Name'),
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: t('general:Phone'),
            selector: (row) => <div className="txt-secondary">{row.phone}</div>,
            sortable: true,
            center: true,
        },
        {
            name: t('general:Email'),
            selector: (row) => row.email,
            sortable: true,
            cell: (row) => <div style={{ textAlign: 'left', width: '100%' }}>{row.email}</div>,
        },
        {
            name: t('partner:Partner'),
            selector: (row) => (row.partnerName ? row.partnerName : t('general:Unknown')),
            sortable: true,
            center: true,
        },
        {
            name: t('general:Action'),
            cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FaEdit
                        style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            color: '#007bff',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleEdit(row.id)}
                    />
                    <FaTrash
                        style={{
                            cursor: 'pointer',
                            color: '#dc3545',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleDelete(row.id)}
                    />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#f2f1fd',
                fontWeight: 'bold',
                textAlign: 'center',
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                fontSize: '14px',
                padding: '10px',
                color: '#7366FF',
            },
        },
        cells: {
            style: {
                padding: '10px',
                fontSize: '14px',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
            },
            stripedStyle: {
                backgroundColor: '#f8f9fa',
            },
        },
    };
    return (
        <>
            <Breadcrumbs mainTitle={t('doctor:Manage')} parent={{ label: t('doctor:Manage'), url: '/doctors' }} />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="3">
                                <h4>{t('doctor:Doctors')}</h4>
                            </Col>
                            <Col md="2" className="text-end">
                                <Button color="primary" className="txt-primary" onClick={openAddForm}>
                                    + {t('doctor:BtnAdd')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="6" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('general:EnterNameOrAddress')}
                                    className="w-auto d-inline-block"
                                    name="search"
                                    id="searchInput"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button color="primary" className="ms-2 txt-primary">
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                        <DataTable
                            data={data}
                            columns={doctorTableColumns}
                            striped={true}
                            center={true}
                            customStyles={customStyles}
                            onRowClicked={handleRowClick}
                        />
                        <Row className="mt-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title={t('general:ConfirmDelete')}
                children={t('doctor:ConfirmDelete')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText={t('general:Cancel')}
                saveText={t('general:Delete')}
            ></CommonModal>
            <ToastContainer />
        </>
    );
};

export default DoctorTable;
