import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Btn, Breadcrumbs } from 'AbstractElements';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Profile = () => {
    const { t } = useTranslation(['general','laboratory','department']);
    const navigate = useNavigate();

    // Lấy thông tin người dùng từ Redux
    const user = useSelector((state) => state.auth.user);
    const [isEditing, setIsEditing] = useState(false);

    // Chuyển hướng đến trang đổi mật khẩu
    const onChangePasswordClick = () => {
        navigate('/password/change');
    };

    return (
        <>
            <Breadcrumbs
                parent={{ label: t('Profile'), url: '/profile' }}
                title={t('Profile')}
                mainTitle={t('Profile')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader
                                className="d-flex justify-content-between align-items-center"
                                style={{ background: '#f2f1fd' }}
                            >
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form">
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('Name')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('Name')}
                                                name="fullName"
                                                value={user.fullName}
                                                readOnly
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('Email')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder={t('Email')}
                                                name="email"
                                                value={user.email}
                                                readOnly
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('laboratory:Laboratory')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="laboratory"
                                                value={user.laboratory ? user.laboratory.name : ''}
                                                readOnly
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('department:Department')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                name="department"
                                                value={user.department ? user.department.name : ''}
                                                readOnly
                                            />
                                        </Col>
                                    </FormGroup>
                                    <div className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:ChangePassword')}</Label>
                                        <Col sm="9">
                                            <Button
                                                color="primary"
                                                className="m-r-15 txt-primary"
                                                type="button"
                                                onClick={onChangePasswordClick}
                                            >
                                                {t('general:ChangePassword')}
                                            </Button>
                                        </Col>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Profile;
