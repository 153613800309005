import { Fragment } from 'react';
import { Breadcrumbs } from 'AbstractElements';
import Create from './Create';

const CreateDoctor = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Thông tin chi tiết" parent="Quản lý hóa chất" title="Thêm mới" />
            <Create />
        </Fragment>
    );
};
export default CreateDoctor;
