import { samplesApi } from 'api/urls';
import api from 'api/config';

class SampleService {
    createSample = async (sample) => {
        try {
            const response = await api.post(`${samplesApi.CREATE_SAMPLE}`, sample);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deleteSample = async (sampleId) => {
        try {
            const response = await api.delete(`${samplesApi.DELETE_SAMPLE}/${sampleId}`);
            return response.message;
        } catch (error) {
            throw error;
        }
    };

    updateSample = async (sampleId, sample) => {
        try {
            const response = await api.put(`${samplesApi.UPDATE_SAMPLE}/${sampleId}`, sample);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getSamples = async (page = 0, size = 10, filterText = null) => {
        try {
            const queryParameters = [];

            queryParameters.push(`page=${page}&size=${size}`);
            if (filterText) {
                queryParameters.push(`search=${filterText}`);
            }
            const queryString = queryParameters.join('&');
            const response = await api.get(`${samplesApi.GET_ALL_SAMPLES}?${queryString}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getSampleInfo = async (sampleId) => {
        try {
            const response = await api.get(`${samplesApi.INFO_SAMPLE}/${sampleId}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new SampleService();
