import React, { Fragment, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { Breadcrumbs, Btn } from 'AbstractElements';
import HeaderCard from 'Components/Common/Component/HeaderCard';
import { BsFillPencilFill, BsFillTrash3Fill } from 'react-icons/bs';

// Dữ liệu mô phỏng cho bảng
export const chemicalsTableData = [
    { id: 1, nameChemicals: 'Bác sĩ mặc định', keyChemicals: '123456789', unitChemicals: 'N/A' },
    { id: 2, nameChemicals: 'Bác sĩ mặc định', keyChemicals: '123456789', unitChemicals: 'N/A' },
    { id: 3, nameChemicals: 'Bác sĩ mặc định', keyChemicals: '123456789', unitChemicals: 'N/A' },
];

// Định nghĩa các cột của bảng
export const chemicalsTableColumns = [
    {
        name: 'ID',
        selector: (row) => row.id,
        sortable: true,
    },
    {
        name: 'Tên hóa chất',
        selector: (row) => row.nameChemicals,
        sortable: true,
    },
    {
        name: 'Mã hóa chất',
        selector: (row) => row.keyChemicals,
        sortable: true,
        center: true,
    },
    {
        name: 'Đơn vị tính',
        selector: (row) => row.unitChemicals,
        sortable: true,
    },
    {
        name: 'Hành động',
        cell: (row) => (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <BsFillPencilFill
                    style={{
                        cursor: 'pointer',
                        marginRight: '10px',
                        color: 'green',
                        fontSize: '1rem',
                    }}
                    onClick={() => handleEdit(row.id)}
                />
                <BsFillTrash3Fill
                    style={{ cursor: 'pointer', color: 'red', fontSize: '1rem' }}
                    onClick={() => handleDelete(row.id)}
                />
            </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        center: true,
    },
];

// Các hàm xử lý
const handleEdit = (id) => {
    console.log('Chỉnh sửa hóa chất có ID:', id);
};

const handleDelete = (id) => {
    console.log('Xóa hóa chất có ID:', id);
};

const DataTableComponent = () => {
    const [data, setData] = useState(chemicalsTableData);

    return (
        <Fragment>
            <DataTable data={data} columns={chemicalsTableColumns} striped={true} center={true} pagination />
        </Fragment>
    );
};

const ChemicalsTable = () => {
    const [filterText, setFilterText] = useState('');
    return (
        <Fragment>
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="Danh sách hóa chất" />
                            <CardBody>
                                <div className="d-flex justify-content-between align-items-center mb-3">
                                    <FormGroup className="mb-0 d-flex align-items-center">
                                        <Label for="filterBy" className="mr-2 mb-0">
                                            Tìm kiếm
                                        </Label>
                                        <Input
                                            type="text"
                                            name="search"
                                            id="searchInput"
                                            placeholder="Nhập tên hoặc mã hóa chất"
                                            value={filterText}
                                            onChange={(e) => setFilterText(e.target.value)}
                                            className="mx-2"
                                            style={{ width: '300px', fontSize: '14px', padding: '6px 10px' }}
                                        />
                                    </FormGroup>
                                    <Btn
                                        attrBtn={{
                                            color: 'success',
                                        }}
                                    >
                                        Thêm mới hóa chất
                                    </Btn>
                                </div>
                                <DataTableComponent />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default ChemicalsTable;
