import { roleApi } from 'api/urls';
import api from 'api/config';
class RoleService {
    getAllRole = async () => {
        try {
            const response = await api.get(`${roleApi.GET_ALL_ROLES}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    getRoleById = async (roleId) => {
        try {
            const response = await api.get(`${roleApi.GET_ROLE_BY_ID}/${roleId}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    createRole = async (roleData) => {
        try {
            const response = await api.post(`${roleApi.CREATE_ROLE}`, roleData);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    updateRole = async (roleId, roleData) => {
        try {
            const response = await api.put(`${roleApi.UPDATE_ROLE}/${roleId}`, roleData);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };

    deleteRole = async (roleId) => {
        try {
            const response = await api.delete(`${roleApi.DELETE_ROLE}/${roleId}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new RoleService();
