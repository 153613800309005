import React, { useCallback, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Btn, Breadcrumbs } from 'AbstractElements';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import DoctorService from 'Services/DoctorService';
import PartnerService from 'Services/PartnerService';

const DoctorForm = () => {
    const { t } = useTranslation(['general', 'doctor', 'partner']);
    const [doctor, setDoctor] = useState({
        name: '',
        email: '',
        phone: '',
        commission: 0,
        partnerId: null,
    });
    const [partners, setPartners] = useState([]);
    const [initialDoctor, setInitialDoctor] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    const fetchData = useCallback(async () => {
        try {
            const [partnerData, doctorData] = await Promise.all([
                PartnerService.getPartners(0, 10, null),
                isEditMode ? DoctorService.getInforDoctor(id) : Promise.resolve(null),
            ]);

            const partnerOptions = partnerData.content.map((partner) => ({
                value: partner.id,
                label: partner.name,
            }));

            setPartners([{ value: null, label: t('general:None') }, ...partnerOptions]);
            if (isEditMode) {
                setDoctor(doctorData);
                setInitialDoctor(doctorData);
            }
        } catch (error) {}
    }, [isEditMode, id, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setDoctor((prevDoctor) => ({
            ...prevDoctor,
            [name]: type === 'number' ? parseFloat(value) : value,
        }));
    };

    const handlePartnerChange = (selectedOption) => {
        setDoctor((prevDoctor) => ({
            ...prevDoctor,
            partnerId: selectedOption.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const { name, email, phone, commission, partnerId } = doctor;
        const payload = {
            name,
            email,
            phone,
            commission,
            partnerId,
        };

        try {
            if (isEditMode) {
                await DoctorService.updateDoctor(id, payload);
                toast.success(t('doctor:EditS'));
            } else {
                await DoctorService.createDoctor(payload);
                toast.success(t('doctor:AddS'));
            }
            navigate('/doctors/');
        } catch (error) {
            handleError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        navigate('/doctors/');
    };

    const handleError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            if (isEditMode && initialDoctor) {
                setDoctor(initialDoctor);
            }
        } else {
            toast.error('Failed to save');
        }
    };

    return (
        <>
            <Breadcrumbs
                parent={{ label: t('doctor:Manage'), url: '/doctors' }}
                title={isEditMode ? t('doctor:Edit') : t('doctor:Add')}
                mainTitle={isEditMode ? t('doctor:Edit') : t('doctor:Add')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Name')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('general:Name')}
                                                name="name"
                                                value={doctor.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('partner:Partners')}</Label>
                                        <Col sm="9">
                                            <Select
                                                options={partners}
                                                onChange={handlePartnerChange}
                                                value={partners.find((option) => option.value === doctor.partnerId)}
                                                placeholder={t('Partner ')}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Phone')}</Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <BsFillTelephoneFill />
                                                </span>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="0___ ___ ___"
                                                    name="phone"
                                                    value={doctor.phone}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Email')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder={t('Email ')}
                                                name="email"
                                                value={doctor.email}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('doctor:Commission')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="number"
                                                placeholder={t('doctor:Commission')}
                                                name="commission"
                                                value={doctor.commission}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DoctorForm;
