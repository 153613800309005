export class ConfigDB {
    static data = {
        settings: {
            layout_type: 'ltr',
            sidebar: {
                type: 'compact-wrapper',
                iconType: 'stroke-svg',
            },
            sidebar_setting: 'default-sidebar',
        },
        color: {
            primary: '#7366ff',
            primary_color: '#7366ff',
            secondary_color: localStorage.getItem('secondary_color') || '#626569',
            mix_background_layout: 'light-only',
        },
        router_animation: 'fadeIn',
    };
}
export default ConfigDB;
