import React from 'react';
import View1 from './View1';
import View2 from './View2';
import DefaultView from './DefaultView';

const SampleSelectedView = ({ sample }) => {
    console.log(sample);

    if (sample?.pack?.name === 'General Checkup') {
        return <View1 sample={sample} />;
    } else if (sample?.pack?.name === 'Advanced Cardio Check') {
        return <View2 sample={sample} />;
    } else {
        return <DefaultView sample={sample} />;
    }
};

export default SampleSelectedView;
