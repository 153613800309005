import React, { Fragment, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Btn, Breadcrumbs } from 'AbstractElements';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PackageCategoryService from 'Services/PackageCategoryService';
import Select from 'react-select';

const PackageCategoryForm = () => {
    const { t } = useTranslation();
    const [packageCategory, setPackageCategory] = useState({
        name: '',
        // packageCategories: [],
    });

    // const [allPackageCategories, setAllPackageCategories] = useState([]);
    const [initialPackageCategory, setInitialPackageCategory] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const categories = await PackageCategoryService.getAllPackageCategories();
                // setAllPackageCategories(categories.map((cat) => ({ value: cat.id, label: cat.name })));

                if (isEditMode) {
                    const packageCategoryData = await PackageCategoryService.getInforPackageCategory(id);
                    setPackageCategory({
                        ...packageCategoryData,
                        packageCategories: packageCategoryData.packageCategories.map((cat) => cat.id),
                    });
                    setInitialPackageCategory(packageCategoryData);
                }
            } catch (error) {
                toast.error(t('packCate:FetchDataPackageCategoryE'));
            }
        };
        fetchData();
    }, [id, isEditMode]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPackageCategory({ ...packageCategory, [name]: value });
    };

    // const handleCategoryChange = (selectedOptions) => {
    //     setPackageCategory({
    //         ...packageCategory,
    //         packageCategories: selectedOptions.map((option) => option.value),
    //     });
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (isEditMode) {
                await PackageCategoryService.updatePackageCategory(id, packageCategory);
                toast.success(t('packCate:EditPackageCategoryS'));
            } else {
                await PackageCategoryService.createPackageCategory(packageCategory);
                toast.success(t('packCate:AddPackageCategoryS'));
            }
            navigate('/package-categories/');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
                if (isEditMode && initialPackageCategory) {
                    setPackageCategory(initialPackageCategory);
                }
            } else {
                toast.error(t('packCate:SavePackageCategoryE'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        navigate('/package-categories/');
    };

    return (
        <>
            <Breadcrumbs
                parent={{ label: t('packCate:ManagePackageCategories'), url: '/package-categories' }}
                title={isEditMode ? t('packCate:EditPackageCategory') : t('packCate:AddPackageCategory')}
                mainTitle={isEditMode ? t('packCate:EditPackageCategory') : t('packCate:AddPackageCategory')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('packCate:EnterNameTypeOfTest')}
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('packCate:PackageCategoryName')}
                                                name="name"
                                                value={packageCategory.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PackageCategoryForm;
