import axios from 'axios';
import AuthService from 'Services/AuthService';
import { REST_API_BASE_URL } from './urls';

const api = axios.create({
    baseURL: REST_API_BASE_URL,
});

api.interceptors.request.use(
    async (config) => {
        const token = AuthService.getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        let language = localStorage.getItem('i18nextLng') || 'en';
        if (language === 'en-GB') {
            language = 'en';
        } else if (language === 'vn') {
            language = 'vi';
        }
        config.headers['Accept-Language'] = language;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default api;
