import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Label } from 'reactstrap';
import {
    ReactFlow,
    addEdge,
    useNodesState,
    useEdgesState,
    MarkerType,
    Controls,
    useReactFlow,
    getOutgoers,
    ReactFlowProvider,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { toast } from 'react-toastify';
import PackageService from 'Services/PackageService';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const SelectStatusForm = () => {
    const { t } = useTranslation(['general', 'status', 'pack']);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [status, setStatus] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [packages, setPackages] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const { getNodes, getEdges } = useReactFlow();

    const isValidConnection = useCallback(
        (connection) => {
            const nodes = getNodes();
            const edges = getEdges();
            const target = nodes.find((node) => node.id === connection.target);
            const hasCycle = (node, visited = new Set()) => {
                if (visited.has(node.id)) return false;

                visited.add(node.id);

                for (const outgoer of getOutgoers(node, nodes, edges)) {
                    if (outgoer.id === connection.source) return true;
                    if (hasCycle(outgoer, visited)) return true;
                }
            };

            if (target.id === connection.source) return false;
            return !hasCycle(target);
        },
        [getNodes, getEdges],
    );

    const fetchStatus = async () => {
        try {
            const statusData = await PackageService.getAllStatus();
            const statusOptions = statusData.map((status) => ({
                value: status.id,
                label: status.name,
            }));
            setStatus(statusOptions);
        } catch (error) {
            toast.error(t('status:FetchErrorMessage'));
        }
    };

    const fetchPackages = async () => {
        try {
            const packageData = await PackageService.getAllPackages();
            const packageOptions = packageData.map((packages) => ({
                value: packages.id,
                label: packages.name,
            }));
            setPackages(packageOptions);
        } catch (error) {
            toast.error('Failed to fetch package.');
        }
    };

    const fetchStatusesByPackageId = async (id) => {
        try {
            const statusData = await PackageService.getStatusesByPackageId(id);

            setStatuses(statusData);
            if (statusData.length === 0) {
                setIsEditMode(false);
            } else {
                setIsEditMode(true);
            }
        } catch (error) {}
    };

    const transformStatusToEdgeAndNode = (statusData) => {
        const newNodes = [];
        const newEdges = [];

        statusData.forEach((statusObj) => {
            const { status, statusOrder } = statusObj;

            newNodes.push({
                id: statusOrder.toString(),
                position: { x: 300, y: statusOrder * 100 },
                data: { label: status.name },
            });

            if (statusOrder > 1) {
                newEdges.push({
                    id: `e${statusOrder - 1}-${statusOrder}`,
                    source: (statusOrder - 1).toString(),
                    target: statusOrder.toString(),
                    animated: true,
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 10,
                        height: 10,
                        color: '#FF0072',
                    },
                    style: {
                        strokeWidth: 1,
                        stroke: '#FF0072',
                    },
                });
            }
        });

        setNodes([...newNodes]);
        setEdges([...newEdges]);

        // console.log('nodes: ', newNodes);
        // console.log('edges: ', newEdges);
    };

    useEffect(() => {
        fetchStatus();
        fetchPackages();
    }, []);

    const handleAddNode = () => {
        if (!selectedStatus) {
            toast.error(t('status:AddNullStatus'));
            return;
        }

        const newNode = {
            id: selectedStatus.value.toString(),
            position: { x: 100 + Math.random() * 100, y: 100 + Math.random() * 100 },
            data: { label: selectedStatus.label, value: selectedStatus.value },
        };
        setNodes((nds) => [...nds, newNode]);
    };

    const onConnect = useCallback(
        (connection) => {
            const { source, target } = connection;

            const newEdge = {
                id: `${source}->${target}`,
                source,
                target,
                animated: true,
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 10,
                    height: 10,
                    color: '#FF0072',
                },
                style: {
                    strokeWidth: 1,
                    stroke: '#FF0072',
                },
            };

            setEdges((eds) => addEdge(newEdge, eds));
        },
        [setEdges],
    );

    const handleChange = (selectedOption) => {
        setSelectedStatus(selectedOption);
    };

    const handlePackageChange = (selectedOption) => {
        setSelectedPackage(selectedOption);
        fetchStatusesByPackageId(selectedOption.value);
        // console.log(statuses);
        // console.log(selectedOption);
        transformStatusToEdgeAndNode(statuses);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        handleAddNode();
        setLoading(false);
    };

    const handleAllStatusSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const statusOrderMap = new Map();
        edges.forEach((edge) => {
            if (!statusOrderMap.has(edge.source)) {
                statusOrderMap.set(edge.source, []);
            }
            statusOrderMap.get(edge.source).push(edge.target);
        });

        const orderedStatusIds = [];
        const visited = new Set();

        const dfs = (node) => {
            if (visited.has(node)) return;
            visited.add(node);
            if (statusOrderMap.has(node)) {
                statusOrderMap.get(node).forEach((neighbor) => dfs(neighbor));
            }
            orderedStatusIds.push(node);
        };

        const allTargets = new Set(edges.map((edge) => edge.target));
        const startNodes = edges.map((edge) => edge.source).filter((source) => !allTargets.has(source));

        startNodes.forEach((node) => dfs(node));

        const statusOrdersObject = {
            statusOrders: orderedStatusIds.reverse().map((statusId, index) => ({
                statusId: parseInt(statusId, 10),
                order: index + 1,
            })),
        };
        // console.log('Status Orders:', statusOrdersObject);
        try {
            if (!isEditMode) {
                await PackageService.createStatus(selectedPackage.value, statusOrdersObject);
                toast.success(t('status:AddSuccessMessage'));
            } else {
                await PackageService.updateStatuses(selectedPackage.value, statusOrdersObject);
                toast.success(t('status:UpdateSuccessMessage'));
            }
        } catch (error) {
            toast.error(t('status:AddErrorMessage'));
        }
        setLoading(false);
    };
    useEffect(() => {
        if (statuses.length > 0) {
            transformStatusToEdgeAndNode(statuses);
        } else {
            setNodes([]);
            setEdges([]);
        }
    }, [statuses]);
    return (
        <div className="row" style={{ height: '100vh' }}>
            <Col sm="5">
                <Card
                    style={{
                        marginTop: '15px',
                    }}
                >
                    <CardHeader>
                        <h5>{t('general:DetailInformation')}</h5>
                    </CardHeader>
                    <CardBody>
                        <Form className="theme-form">
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label">{t('pack:Choose')}</Label>
                                <Col sm="9">
                                    <Select
                                        options={packages}
                                        onChange={handlePackageChange}
                                        placeholder={t('pack:Choose')}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label">{t('status:Choose')}</Label>
                                <Col sm="9">
                                    <Select
                                        options={status}
                                        onChange={handleChange}
                                        placeholder={t('status:Choose')}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <CardFooter className="text-start">
                                <Button
                                    className="txt-primary"
                                    onClick={handleSubmit}
                                    style={{
                                        minWidth: '120px',
                                    }}
                                    color="primary"
                                    disabled={loading}
                                >
                                    {t('general:BtnAdd')}
                                </Button>
                            </CardFooter>
                        </Form>
                    </CardBody>
                </Card>
                <Button
                    className="txt-primary"
                    color="primary"
                    style={{
                        minWidth: '120px',
                    }}
                    onClick={handleAllStatusSubmit}
                >
                    {isEditMode ? t('general:Update') : t('general:Submit')}
                </Button>
            </Col>
            <Col sm="7" style={{ height: '100vh' }}>
                <ReactFlow
                    style={{ backgroundColor: '#e0dddd', height: '100%' }}
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={onNodesChange}
                    onEdgesChange={onEdgesChange}
                    onConnect={onConnect}
                    isValidConnection={isValidConnection}
                    fitView
                    zoomOnScroll={false}
                    defaultViewport={{ x: 0, y: 0, zoom: 0.5 }}
                >
                    <Controls position="top-left" />
                </ReactFlow>
            </Col>
        </div>
    );
};

const WrappedSelectStatusForm = () => (
    <ReactFlowProvider>
        <SelectStatusForm />
    </ReactFlowProvider>
);

export default WrappedSelectStatusForm;
