import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Button, Input, Card, CardBody } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Breadcrumbs } from 'AbstractElements';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import PaginationComponent from 'Components/Pagination/PaginationComponent';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import PartnerService from 'Services/PartnerService';

const PartnerTable = () => {
    const { t } = useTranslation(['general', 'partner']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedPartnerId, setSelectedPartnerId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        debouncedFetchPartners(currentPage, pageSize, filterText);
        return () => {
            debouncedFetchPartners.cancel();
        };
    }, [currentPage, pageSize, filterText]);

    const fetchPartners = async (page = 0, size = 10, filterText) => {
        try {
            const response = await PartnerService.getPartners(page, size, filterText);
            setData(response.content);
            setTotalRecords(response.totalElements);
        } catch (error) {
            toast.error(t('partner:FetchDataPartnerE'));
        }
    };

    const debouncedFetchPartners = useCallback(
        debounce((page, size, filterText) => {
            fetchPartners(page, size, filterText);
        }, 300),
        [],
    );

    const handleDelete = (id) => {
        setSelectedPartnerId(id);
        setModal(true);
    };

    const handleEdit = (id) => {
        navigate(`/partners/edit/${id}`);
    };

    const confirmDelete = async () => {
        try {
            await PartnerService.deletePartner(selectedPartnerId);
            setData(data.filter((partner) => partner.id !== selectedPartnerId));
            setModal(false);
            toast.success(t('partner:DeletePartnerS'));
        } catch (error) {
            toast.error(t('partner:DeletePartnerE'));
        }
    };

    const openAddForm = () => {
        navigate('/partners/create');
    };

    const toggleModal = () => setModal(!modal);

    const partnerTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
        },
        {
            name: t('partner:PartnerType'),
            selector: (row) => {
                switch (row.partnerType) {
                    case 1:
                        return t('partner:Clinic');
                    case 2:
                        return t('partner:Hospital');
                    default:
                        return t('partner:Unknown');
                }
            },
            sortable: true,
            center: true,
        },
        {
            name: t('partner:PartnerName'),
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: t('general:Phone'),
            selector: (row) => <div className="txt-secondary">{row.phone}</div>,
            sortable: true,
            center: true,
        },
        {
            name: t('general:Address'),
            selector: (row) => row.address,
            sortable: true,
        },
        {
            name: t('general:Province/City'),
            selector: (row) => row.province,
            sortable: true,
        },
        {
            name: t('general:District'),
            selector: (row) => row.district,
            sortable: true,
        },
        {
            name: t('general:Email'),
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: t('general:Action'),
            cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FaEdit
                        style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            color: '#007bff',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleEdit(row.id)}
                    />
                    <FaTrash
                        style={{
                            cursor: 'pointer',
                            color: '#dc3545',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleDelete(row.id)}
                    />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#f2f1fd',
                fontWeight: 'bold',
                textAlign: 'center',
                borderTop: '1px solid #ccc',
                borderBottom: '1px solid #ccc',
                fontSize: '14px',
                padding: '10px',
                color: '#7366FF',
            },
        },
        cells: {
            style: {
                padding: '10px',
                fontSize: '14px',
            },
        },
        rows: {
            style: {
                minHeight: '50px',
            },
            stripedStyle: {
                backgroundColor: '#f8f9fa',
            },
        },
    };

    return (
        <>
            <Breadcrumbs
                mainTitle={t('partner:ManagePartners')}
                parent={{ label: t('partner:ManagePartners'), url: '/partners' }}
            />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="3">
                                <h4>{t('partner:Partners')}</h4>
                            </Col>
                            <Col md="3" className="text-end">
                                <Button color="primary" className="txt-primary" onClick={openAddForm}>
                                    + {t('partner:BtnAddPartner')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="6" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('general:EnterNameOrAddress')}
                                    className="w-auto d-inline-block"
                                    name="search"
                                    id="searchInput"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button color="primary" className="ms-2 txt-primary">
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                        <DataTable
                            data={data}
                            columns={partnerTableColumns}
                            striped={true}
                            center={true}
                            customStyles={customStyles}
                        />
                        <Row className="mt-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
            <CommonModal
                isOpen={modal}
                title={t('partner:ConfirmDelete')}
                children={t('partner:ConfirmDeleteMessagePartner')}
                toggler={toggleModal}
                action={confirmDelete}
                closeText={t('general:Cancel')}
                saveText={t('general:Delete')}
            ></CommonModal>
            <ToastContainer />
        </>
    );
};

export default PartnerTable;
