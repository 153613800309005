import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import CustomizerContext from '../../_helper/Customizer';

const SidebarIcon = () => {
  const { layoutURL } = useContext(CustomizerContext);
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`}>
        <label>LIMS</label>
      </Link>
    </div>
  );
};

export default SidebarIcon;