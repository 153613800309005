import React, { Fragment, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import PartnerService from 'Services/PartnerService';
import { Breadcrumbs, Btn } from 'AbstractElements';
import ProvinceDistrictSelect from 'CommonElements/ProvinceDistrictSelection/ProvinceDistrictSelection';

const PartnerForm = () => {
    const { t } = useTranslation(['general', 'partner']);
    const [partner, setPartner] = useState({
        partnerType: '',
        name: '',
        address: '',
        province: '',
        district: '',
        phone: '',
        email: '',
    });

    const [initialPartner, setInitialPartner] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedWard, setSelectedWard] = useState(null);
    useEffect(() => {
        const fetchPartner = async () => {
            if (isEditMode) {
                try {
                    const partnerData = await PartnerService.getInforPartner(id);
                    setPartner(partnerData);
                    setInitialPartner(partnerData);
                    setSelectedProvince({ value: partnerData.province, label: partnerData.province });
                    setSelectedDistrict({ value: partnerData.district, label: partnerData.district });
                } catch (error) {
                    toast.error(t('partner:FetchDataPartnerE'));
                }
            }
        };
        fetchPartner();
    }, [id, isEditMode, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPartner({ ...partner, [name]: value });
    };

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvince(selectedOption);
        setPartner({ ...partner, province: selectedOption ? selectedOption.label : '' });
        setSelectedDistrict(null);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrict(selectedOption);
        setPartner({ ...partner, district: selectedOption ? selectedOption.label : '' });
    };

    const handleWardChange = (selectedOption) => {
        setSelectedWard(selectedOption);
        // setLaboratory({ ...laboratory, ward: selectedOption ? selectedOption.label : '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (isEditMode) {
                await PartnerService.updatePartner(id, partner);
                toast.success(t('partner:EditPartnerS'));
            } else {
                await PartnerService.createPartner(partner);
                toast.success(t('partner:AddPartnerS'));
            }
            navigate('/partners/');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
                if (isEditMode && initialPartner) {
                    setPartner(initialPartner);
                }
            } else {
                toast.error(t('partner:SavePartnerE'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        navigate('/partners/');
    };

    return (
        <Fragment>
            <Breadcrumbs
                mainTitle={isEditMode ? t('partner:EditPartner') : t('partner:AddPartner')}
                parent={{ label: t('partner:ManagePartners'), url: '/partners' }}
                title={isEditMode ? t('partner:EditPartner') : t('partner:AddPartner')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('partner:PartnerType')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="select"
                                                name="partnerType"
                                                value={partner.partnerType}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="" disabled>
                                                    {t('partner:PartnerType')}
                                                </option>
                                                <option value="1">{t('partner:Clinic')}</option>
                                                <option value="2">{t('partner:Hospital')}</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('partner:PartnerName')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('partner:PartnerName')}
                                                name="name"
                                                value={partner.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Address')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('general:Address')}
                                                name="address"
                                                value={partner.address}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    {/* Province and District Select */}
                                    <ProvinceDistrictSelect
                                        selectedProvince={selectedProvince}
                                        setSelectedProvince={handleProvinceChange}
                                        selectedDistrict={selectedDistrict}
                                        setSelectedDistrict={handleDistrictChange}
                                        selectedWard={selectedWard}
                                        setSelectedWard={handleWardChange}
                                    />

                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Phone')}</Label>
                                        <Col sm="9">
                                            <div className="input-group">
                                                <span className="input-group-text">
                                                    <BsFillTelephoneFill />
                                                </span>
                                                <Input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="0___ ___ ___"
                                                    name="phone"
                                                    value={partner.phone}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Email')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder={t('general:Email')}
                                                name="email"
                                                value={partner.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button
                                            color="outline-secondary"
                                            type="reset"
                                            onClick={handleReset}
                                            className="ms-2"
                                        >
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default PartnerForm;
