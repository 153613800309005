import React, { useContext } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import H3 from '../Headings/H3Element';
import CustomizerContext from '../../_helper/Customizer';
import SvgIcon from '../../Components/Common/Component/SvgIcon';

const Breadcrumbs = (props) => {
    const { layoutURL } = useContext(CustomizerContext);

    // Custom styles for parent link
    const parentStyle = {
        color: 'inherit', // Keeps the current text color
        textDecoration: 'none', // Removes underline
    };

    return (
        <Container fluid>
            <div className="page-title">
                <Row>
                    <Col xs="6">
                        <H3>{props.mainTitle}</H3>
                    </Col>
                    <Col xs="6">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to={`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`}>
                                    <SvgIcon iconId="stroke-home" />
                                </Link>
                            </li>
                            {props.parent ? (
                                typeof props.parent === 'string' ? (
                                    <li className="breadcrumb-item">{props.parent}</li>
                                ) : (
                                    <li className="breadcrumb-item">
                                        <Link to={props.parent.url} style={parentStyle}>
                                            {props.parent.label}
                                        </Link>
                                    </li>
                                )
                            ) : null}
                            {props.subParent && <li className="breadcrumb-item">{props.subParent}</li>}
                            {props.title && <li className="breadcrumb-item active">{props.title}</li>}
                        </ol>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default Breadcrumbs;
