import React, { useState, useEffect, Fragment } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Breadcrumbs } from 'AbstractElements';
import DepartmentService from 'Services/DepartmentService';
import LaboratoryService from 'Services/LaboratoryService';
import UserService from 'Services/UserService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const DepartmentForm = () => {
    const { t } = useTranslation(['general', 'department']);
    const { id } = useParams(); // Get ID from URL for edit mode
    const navigate = useNavigate();

    const [department, setDepartment] = useState({
        name: '',
        personInChargeId: '',
        laboratoryId: '',
    });
    const [laboratories, setLaboratories] = useState([]);
    const [personInCharges, setPersonInCharges] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedLaboratory, setSelectedLaboratory] = useState(null);
    const [loadingLabs, setLoadingLabs] = useState(true);

    useEffect(() => {
        const fetchLaboratories = async () => {
            try {
                const labs = await LaboratoryService.getAllLaboratories();
                setLaboratories(labs);
            } catch (error) {
                toast.error(t('department:FetchLaboratoryE'));
            } finally {
                setLoadingLabs(false);
            }
        };
        fetchLaboratories();
    }, [t]);

    useEffect(() => {
        const fetchDepartment = async () => {
            if (!id) return;
            try {
                const departmentData = await DepartmentService.getDepartmentDetails(id);
                setDepartment({
                    name: departmentData.name || '',
                    personInChargeId: departmentData.personInChargeId || '',
                    laboratoryId: departmentData.laboratoryId || '',
                });

                if (departmentData.laboratoryId) {
                    const users = await UserService.getUserByLabId(departmentData.laboratoryId);
                    setPersonInCharges(users);
                }
            } catch (error) {
                toast.error(t('department:FetchDataDepartmentE'));
            }
        };
        fetchDepartment();
    }, [id, t]);

    useEffect(() => {
        const fetchPersonInCharges = async () => {
            if (!department.laboratoryId) {
                setPersonInCharges([]);
                return;
            }
            try {
                const users = await UserService.getUserByLabId(department.laboratoryId);
                setPersonInCharges(users);
            } catch (error) {
                toast.error(t('department:FetchPersonInChargeError'));
            }
        };
        fetchPersonInCharges();
    }, [department.laboratoryId, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDepartment((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (id) {
                await DepartmentService.updateDepartment(id, department);
                toast.success(t('department:UpdateDepartmentS'));
            } else {
                await DepartmentService.createDepartment(department);
                toast.success(t('department:AddDepartmentS'));
            }
            navigate('/departments');
        } catch (error) {
            toast.error(t('department:SaveDepartmentE'));
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        setDepartment({ name: '', personInChargeId: '', laboratoryId: '' });
        navigate('/departments');
    };

    return (
        <Fragment>
            <Breadcrumbs
                mainTitle={id ? t('department:EditDepartment') : t('department:AddDepartment')}
                parent={{ label: t('department:ManageDepartments'), url: '/departments' }}
                title={id ? t('department:EditDepartment') : t('department:AddDepartment')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('department:LaboratoryName')}
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                type="select"
                                                name="laboratoryId"
                                                value={department.laboratoryId}
                                                onChange={handleChange}
                                                required
                                                disabled={loadingLabs}
                                            >
                                                <option value="">{t('department:SelectLaboratory')}</option>
                                                {laboratories.map((lab) => (
                                                    <option key={lab.id} value={lab.id}>
                                                        {lab.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('department:DepartmentName')}
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                type="text"
                                                name="name"
                                                placeholder={t('department:DepartmentName')}
                                                value={department.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('department:PersonInChargeName')}
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                type="select"
                                                name="personInChargeId"
                                                value={department.personInChargeId}
                                                onChange={handleChange}
                                                disabled={!department.laboratoryId}
                                            >
                                                <option value="">{t('department:SelectPersonInCharge')}</option>
                                                {personInCharges.map((person) => (
                                                    <option key={person.userId} value={person.userId}>
                                                        {person.fullName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={loading || !department.laboratoryId || !department.name}
                                        >
                                            {loading
                                                ? t('general:Processing')
                                                : id
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button
                                            color="outline-secondary"
                                            type="reset"
                                            onClick={handleReset}
                                            className="ms-2"
                                        >
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default DepartmentForm;
