import React, { Fragment, useEffect, useState } from 'react';
import { administrativeUnit } from 'assets/json/ProvinceDistrictData';
import { Col, FormGroup, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const AdministrativeUnitSelect = ({
    selectedProvince,
    setSelectedProvince,
    selectedDistrict,
    setSelectedDistrict,
    selectedWard,
    setSelectedWard,
}) => {
    const { t } = useTranslation();
    const [districtOptions, setDistrictOptions] = useState([]);
    const [wardOptions, setWardOptions] = useState([]);

    const provinceOptions = administrativeUnit.map((province) => ({
        value: province.code,
        label: province.name,
    }));

    useEffect(() => {
        if (selectedProvince) {
            const province = administrativeUnit.find((prov) => prov.code === selectedProvince.value);
            const districts = province
                ? province.level2s.map((district) => ({
                      value: district.code,
                      label: district.name,
                  }))
                : [];
            setDistrictOptions(districts);
            setSelectedDistrict(null);
            setWardOptions([]);
        } else {
            setDistrictOptions([]);
            setWardOptions([]);
        }
    }, [selectedProvince]);

    useEffect(() => {
        if (selectedDistrict) {
            const province = administrativeUnit.find((prov) => prov.code === selectedProvince.value);
            const district = province.level2s.find((dist) => dist.code === selectedDistrict.value);
            const wards = district
                ? district.level3s.map((ward) => ({
                      value: ward.code,
                      label: ward.name,
                  }))
                : [];
            setWardOptions(wards);
            setSelectedWard(null);
        } else {
            setWardOptions([]);
        }
    }, [selectedDistrict, selectedProvince]);

    return (
        <Fragment>
            <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" for="province">
                    {t('general:Province/City')}:<span className="text-danger">*</span>
                </Label>
                <Col sm="9">
                    <Select
                        id="province"
                        options={provinceOptions}
                        value={selectedProvince}
                        onChange={setSelectedProvince}
                        placeholder={t('general:Province/City')}
                        className="js-example-basic-single col-sm-12"
                        required
                    />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" for="province">
                    {t('general:District')}
                    <span className="text-danger">*</span>
                </Label>
                <Col sm="9">
                    <Select
                        id="district"
                        options={districtOptions}
                        value={selectedDistrict}
                        onChange={setSelectedDistrict}
                        placeholder={t('general:District')}
                        isDisabled={!selectedProvince}
                        required
                    />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" for="ward">
                    {t('general:Ward')}
                    <span className="text-danger">*</span>
                </Label>
                <Col sm="9">
                    <Select
                        id="ward"
                        options={wardOptions}
                        value={selectedWard}
                        onChange={setSelectedWard}
                        placeholder={t('general:Ward')}
                        isDisabled={!selectedDistrict}
                        required
                    />
                </Col>
            </FormGroup>
        </Fragment>
    );
};

export default AdministrativeUnitSelect;
