import React, { Fragment } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { privateRoutes } from './privateRoutes';
import AppLayout from '../Layout/Layout';
import Error404 from 'Components/Pages/ErrorPages/ErrorPage404';

const RouteHandler = () => {
    const user = useSelector((state) => state.auth.user);
    const userPermissions = user.permissions || [];

    return (
        <Routes>
            {privateRoutes.map(({ path, Component, permission }, i) => {
                if (!user || !user.userId) {
                    return (
                        <Route
                            key={i}
                            path={path}
                            element={<Navigate to={`${process.env.PUBLIC_URL}/login`} />}
                        />
                    );
                }

                if (permission && !userPermissions.includes(permission)) {
                    return (
                        <Route
                            key={i}
                            path={path}
                            element={<Navigate to={`${process.env.PUBLIC_URL}/unauthorized`} />}
                        />
                    );
                }

                return (
                    <Fragment key={i}>
                        <Route element={<AppLayout />}>
                            <Route path={path} element={Component} />
                        </Route>
                    </Fragment>
                );
            })}
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
};

export default RouteHandler;
