import React, { useCallback, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import RoleService from 'Services/RoleService';
import { Breadcrumbs } from 'AbstractElements';

const RoleForm = () => {
    const { t } = useTranslation(['general', 'role']);
    const [role, setRole] = useState({
        roleName: '',
        description: '',
    });
    const [initialRole, setInitialRole] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    const fetchData = useCallback(async () => {
        try {
            if (isEditMode) {
                const roleData = await RoleService.getRoleById(id);
                setRole(roleData);
                setInitialRole(roleData);
            }
        } catch (error) {
            toast.error(t('general:ErrorLoadingData'));
        }
    }, [isEditMode, id, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setRole((prevRole) => ({
            ...prevRole,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            const roleData = {
                roleName: role.roleName,
                description: role.description,
            };

            if (isEditMode) {
                await RoleService.updateRole(id, roleData);
                toast.success(t('role:EditS'));
            } else {
                await RoleService.createRole(roleData);
                toast.success(t('role:AddS'));
            }
            navigate('/roles/');
        } catch (error) {
            handleError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        navigate('/roles/');
    };

    const handleError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            if (isEditMode && initialRole) {
                setRole(initialRole);
            }
        } else {
            toast.error('Failed to save');
        }
    };

    return (
        <>
            <Breadcrumbs
                parent={{ label: t('role:RoleManage'), url: '/roles' }}
                title={isEditMode ? t('role:RoleEdit') : t('role:RoleCreate')}
                mainTitle={isEditMode ? t('role:RoleEdit') : t('role:RoleCreate')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('role:RoleName')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('role:RoleName')}
                                                name="roleName"
                                                value={role.roleName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('role:RoleDescription')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('role:RoleDescription')}
                                                name="description"
                                                value={role.description}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default RoleForm;
