import { permissionApi } from 'api/urls';
import api from 'api/config';

class PermissionService {
    getAllPermission = async () => {
        try {
            const response = await api.get(`${permissionApi.GET_ALL_PERMISSIONS}`);
            return response.data.result;
        } catch (error) {
            throw error;
        }
    };
}

export default new PermissionService();
