import React, { useCallback, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
} from 'reactstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Btn, Breadcrumbs } from 'AbstractElements';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import RoleService from 'Services/RoleService';
import UserService from 'Services/UserService';
import DepartmentService from 'Services/DepartmentService';
import LaboratoryService from 'Services/LaboratoryService';

const UserForm = () => {
    const { t } = useTranslation(['user', 'general', 'department', 'laboratory']);
    const [user, setUser] = useState({
        email: '',
        signalKey: '',
        fullName: '',
        password: '',
        rePassword: '',
        departmentId: null,
        laboratoryId: null,
        roles: [],
    });
    const [departments, setDepartments] = useState([]);
    const [laboratories, setLaboratories] = useState([]);
    const [roles, setRoles] = useState([]);
    const [initialUser, setInitialUser] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDepartmentDisabled, setIsDepartmentDisabled] = useState(true);

    const navigate = useNavigate();
    const { userId } = useParams();
    const isEditMode = Boolean(userId);

    const fetchData = useCallback(async () => {
        try {
            const [laboratoryData, roleData, userData] = await Promise.all([
                LaboratoryService.getAllLaboratories(),
                RoleService.getAllRole(),
                isEditMode ? UserService.getInforUser(userId) : Promise.resolve(null),
            ]);

            const laboratoryOptions = laboratoryData.map((laboratory) => ({
                value: laboratory.id,
                label: laboratory.name,
            }));

            const roleOptions = roleData.map((role) => ({
                value: role.roleName,
                label: role.description,
            }));

            setLaboratories([{ value: null, label: t('laboratory:Laboratory') }, ...laboratoryOptions]);
            setRoles(roleOptions);
            if (isEditMode && userData) {
                setUser(userData);
                setInitialUser(userData);
                if (userData.laboratoryId) {
                    await handleLaboratoryChange(
                        laboratoryOptions.find((lab) => lab.value === userData.laboratoryId),
                        userData.departmentId,
                    );
                }
            }
        } catch (error) {}
    }, [isEditMode, userId, t]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleRoleChange = (selectedOption) => {
        const selectedRoles = selectedOption.map((option) => option.value);
        setUser((prevUser) => ({
            ...prevUser,
            roles: selectedRoles,
        }));
    };

    const handleDepartmentChange = (selectedOption) => {
        setUser((prevUser) => ({
            ...prevUser,
            departmentId: selectedOption.value,
        }));
    };

    const handleLaboratoryChange = async (selectedOption, preselectedDepartmentId = null) => {
        const labId = selectedOption.value;
        setUser((prevUser) => ({
            ...prevUser,
            laboratoryId: labId,
            departmentId: preselectedDepartmentId ? preselectedDepartmentId : null,
        }));

        setIsDepartmentDisabled(true);
        setDepartments([]);

        if (labId) {
            try {
                const departmentData = await DepartmentService.getAllDepartmentsByLabId(labId);
                const departmentOptions = departmentData.map((department) => ({
                    value: department.id,
                    label: department.name,
                }));

                setDepartments(departmentOptions);

                if (preselectedDepartmentId) {
                    setUser((prevUser) => ({
                        ...prevUser,
                        departmentId: preselectedDepartmentId,
                    }));
                }
            } catch (error) {
            } finally {
                setIsDepartmentDisabled(false);
            }
        } else {
            setDepartments([]);
            setIsDepartmentDisabled(true);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        const { email, signalKey, fullName, password, rePassword, departmentId, laboratoryId, roles } = user;
        const payload = {
            email,
            signalKey: signalKey || '',
            fullName,
            departmentId,
            laboratoryId,
            roles,
            ...(isEditMode && { password: password || '', rePassword: rePassword || '' }),
        };

        try {
            if (isEditMode) {
                await UserService.updateUser(userId, payload);
                toast.success(t('user:EditS'));
            } else {
                await UserService.createUser(payload);
                toast.success(t('user:AddS'));
            }
            navigate('/users/');
        } catch (error) {
            handleError(error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleReset = () => {
        navigate('/users/');
    };

    const handleError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            toast.error(error.response.data.message);
            if (isEditMode && initialUser) {
                setUser(initialUser);
            }
        } else {
            toast.error(t('SaveUserE'));
        }
    };

    return (
        <>
            <Breadcrumbs
                parent={{ label: t('user:Manage'), url: '/users' }}
                title={isEditMode ? t('user:Edit') : t('user:Add')}
                mainTitle={isEditMode ? t('user:Edit') : t('user:Add')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('general:DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Name')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('general:Name')}
                                                name="fullName"
                                                value={user.fullName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('general:Email')}</Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="email"
                                                placeholder={t('general:Email')}
                                                name="email"
                                                value={user.email}
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {isEditMode && (
                                        <>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">{t('user:SignalKey')}</Label>
                                                <Col sm="9">
                                                    <Input
                                                        className="form-control"
                                                        type="textarea"
                                                        placeholder={t('user:SignalKey')}
                                                        name="signalKey"
                                                        value={user.signalKey}
                                                        onChange={handleChange}
                                                        rows="3"
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">
                                                    {t('general:Password')}
                                                </Label>
                                                <Col sm="9">
                                                    <Input
                                                        className="form-control"
                                                        type="password"
                                                        placeholder={t('general:Password')}
                                                        name="password"
                                                        value={user.password}
                                                        onChange={handleChange}
                                                        required={!isEditMode}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup className="row">
                                                <Label className="col-sm-3 col-form-label">
                                                    {t('general:RePassword')}
                                                </Label>
                                                <Col sm="9">
                                                    <Input
                                                        className="form-control"
                                                        type="password"
                                                        placeholder={t('general:RePassword')}
                                                        name="rePassword"
                                                        value={user.rePassword}
                                                        onChange={handleChange}
                                                        required={!isEditMode}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </>
                                    )}
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('user:Role')}</Label>
                                        <Col sm="9">
                                            <Select
                                                isMulti
                                                options={roles}
                                                onChange={handleRoleChange}
                                                value={roles.filter((option) => user.roles.includes(option.value))}
                                                placeholder={t('user:Role')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('laboratory:Laboratory')}</Label>
                                        <Col sm="9">
                                            <Select
                                                options={laboratories}
                                                onChange={handleLaboratoryChange}
                                                value={laboratories.find(
                                                    (option) => option.value === user.laboratoryId,
                                                )}
                                                placeholder={t('laboratory:Laboratory')}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">{t('department:Department')}</Label>
                                        <Col sm="9">
                                            <Select
                                                options={departments}
                                                onChange={handleDepartmentChange}
                                                value={departments.find((option) => option.value === user.departmentId)}
                                                placeholder={t('department:Department')}
                                                isDisabled={isDepartmentDisabled}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default UserForm;
