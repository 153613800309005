// frontend/src/Route/privateRoutes.js
import Default from 'Components/Pages/Dashboard/Default';
import CreateDoctor from 'Components/Pages/Chemicals/CreateCustomerForm'; // Sửa tên nếu cần
import Chemicals from 'Components/Pages/Chemicals/Table';
import CustomerTable from 'Components/Pages/Customers/CustomerTable';
import CreateCustomerForm from 'Components/Pages/Customers/CreateCustomerForm';
import DoctorForm from 'Components/Pages/Doctor/DoctorForm';
import DoctorTable from 'Components/Pages/Doctor/DoctorTable';
import PackageListContain from 'Components/Pages/Packages/PackagesTable';
import PartnerForm from 'Components/Pages/Partner/PartnerForm';
import PartnerTable from 'Components/Pages/Partner/PartnerTable';
import PackageCategoryForm from 'Components/Pages/PackageCategory/PackageCategoryForm';
import PackageCategoryTable from 'Components/Pages/PackageCategory/PackageCategoryTable';
import PackagesForm from 'Components/Pages/Packages/PackagesForm';
import PackagesTable from 'Components/Pages/Packages/PackagesTable'; // Thêm PackagesTable
import SampleTable from 'Components/Pages/Samples/SampleTable';
import SampleForm from 'Components/Pages/Samples/SampleForm';
import SelectStatusForm from 'Components/Pages/Packages/SelectStatusForm';
import LaboratoryForm from 'Components/Pages/Laboratory/LaboratoryForm';
import LaboratoryTable from 'Components/Pages/Laboratory/LaboratoryTable';
import UserForm from 'Components/Pages/Users/UserForm';
import UserTable from 'Components/Pages/Users/UserTable';
import ChangePasswordForm from 'Components/Pages/Profile/ChangePasswordForm';
import ResetPasswordForm from 'Components/Pages/Profile/ResetPasswordForm';
// import ForgotPasswordForm from 'Components/Pages/Profile/ForgotPasswordForm';
import DepartmentForm from 'Components/Pages/Departments/DepartmentForm';
import DepartmentTable from 'Components/Pages/Departments/DepartmentTable';
import Profile from 'Components/Pages/Profile/Profile';
import RoleManager from 'Components/Pages/Role/RoleManager';
import RoleForm from 'Components/Pages/Role/RoleForm';
import SampleList from '../Components/Pages/Samples/SampleList';

export const privateRoutes = [
    // Dashboard
    {
        path: `${process.env.PUBLIC_URL}/dashboard/`,
        Component: <Default />,
    },
    // Chemicals
    {
        path: `${process.env.PUBLIC_URL}/chemicals/create`,
        Component: <CreateDoctor />, // Ensure this name is correct
        permission: 'MODIFY_CHEMICALS',
    },
    {
        path: `${process.env.PUBLIC_URL}/chemicals`,
        Component: <Chemicals />,
        permission: 'ACCESS_CHEMICALS',
    },
    // Partners
    {
        path: `${process.env.PUBLIC_URL}/partners/create`,
        Component: <PartnerForm />,
        permission: 'MODIFY_PARTNERS',
    },
    {
        path: `${process.env.PUBLIC_URL}/partners`,
        Component: <PartnerTable />,
        permission: 'ACCESS_PARTNERS',
    },
    {
        path: `${process.env.PUBLIC_URL}/partners/edit/:id`,
        Component: <PartnerForm />,
        permission: 'MODIFY_PARTNERS',
    },
    // Customers
    {
        path: `${process.env.PUBLIC_URL}/customers/`,
        Component: <CustomerTable />,
        permission: 'ACCESS_CUSTOMERS',
    },
    {
        path: `${process.env.PUBLIC_URL}/customers/create`,
        Component: <CreateCustomerForm />,
        permission: 'MODIFY_CUSTOMERS',
    },
    {
        path: `${process.env.PUBLIC_URL}/customers/edit/:id`,
        Component: <CreateCustomerForm />,
        permission: 'MODIFY_CUSTOMERS',
    },
    // Packages
    {
        path: `${process.env.PUBLIC_URL}/packages`,
        Component: <PackageListContain />,
        permission: 'ACCESS_PACKAGES',
    },
    {
        path: `${process.env.PUBLIC_URL}/packages/create`,
        Component: <PackagesForm />,
        permission: 'MODIFY_PACKAGES',
    },
    {
        path: `${process.env.PUBLIC_URL}/packages/edit/:id`,
        Component: <PackagesForm />,
        permission: 'MODIFY_PACKAGES',
    },
    {
        path: `${process.env.PUBLIC_URL}/packages`,
        Component: <PackagesTable />,
        permission: 'ACCESS_PACKAGES',
    },
    // Laboratories
    {
        path: `${process.env.PUBLIC_URL}/laboratories/create`,
        Component: <LaboratoryForm />,
        permission: 'MODIFY_LABORATORIES',
    },
    {
        path: `${process.env.PUBLIC_URL}/laboratories`,
        Component: <LaboratoryTable />,
        permission: 'ACCESS_LABORATORIES',
    },
    {
        path: `${process.env.PUBLIC_URL}/laboratories/edit/:id`,
        Component: <LaboratoryForm />,
        permission: 'MODIFY_LABORATORIES',
    },
    // Package Categories
    {
        path: `${process.env.PUBLIC_URL}/package-categories/create`,
        Component: <PackageCategoryForm />,
        permission: 'MODIFY_PACKAGES',
    },
    {
        path: `${process.env.PUBLIC_URL}/package-categories`,
        Component: <PackageCategoryTable />,
        permission: 'ACCESS_PACKAGES',
    },
    {
        path: `${process.env.PUBLIC_URL}/package-categories/edit/:id`,
        Component: <PackageCategoryForm />,
        permission: 'MODIFY_PACKAGES',
    },
    // Doctors
    {
        path: `${process.env.PUBLIC_URL}/doctors/create`,
        Component: <DoctorForm />,
        permission: 'MODIFY_DOCTORS',
    },
    {
        path: `${process.env.PUBLIC_URL}/doctors`,
        Component: <DoctorTable />,
        permission: 'ACCESS_DOCTORS',
    },
    {
        path: `${process.env.PUBLIC_URL}/doctors/edit/:id`,
        Component: <DoctorForm />,
        permission: 'MODIFY_DOCTORS',
    },
    // Samples
    {
        path: `${process.env.PUBLIC_URL}/samples/create`,
        Component: <SampleForm />,
        permission: 'MODIFY_SAMPLES',
    },
    {
        path: `${process.env.PUBLIC_URL}/samples`,
        Component: <SampleTable />,
        permission: 'ACCESS_SAMPLES',
    },
    {
        path: `${process.env.PUBLIC_URL}/samples2`,
        Component: <SampleList />,
        // Component: <SampleTable />,
        permission: 'ACCESS_SAMPLES',
    },
    {
        path: `${process.env.PUBLIC_URL}/samples/edit/:id`,
        Component: <SampleForm />,
        permission: 'MODIFY_SAMPLES',
    },
    // Flow
    {
        path: `${process.env.PUBLIC_URL}/flow`,
        Component: <SelectStatusForm />,
        permission: 'MANAGE_SYSTEM',
    },
    // Users (Requires system management permission)
    {
        path: `${process.env.PUBLIC_URL}/users/create`,
        Component: <UserForm />,
        permission: 'MANAGE_SYSTEM',
    },
    {
        path: `${process.env.PUBLIC_URL}/users`,
        Component: <UserTable />,
        permission: 'MANAGE_SYSTEM',
    },
    {
        path: `${process.env.PUBLIC_URL}/users/edit/:userId`,
        Component: <UserForm />,
        permission: 'MANAGE_SYSTEM',
    },
    // Roles
    {
        path: `${process.env.PUBLIC_URL}/roles`,
        Component: <RoleManager />,
        permission: 'MANAGE_SYSTEM',
    },
    {
        path: `${process.env.PUBLIC_URL}/roles/create`,
        Component: <RoleForm />,
        permission: 'MANAGE_SYSTEM',
    },
    {
        path: `${process.env.PUBLIC_URL}/roles/edit/:id`,
        Component: <RoleForm />,
        permission: 'MANAGE_SYSTEM',
    },
    // Password Management (Only system managers can modify or reset passwords)
    {
        path: `${process.env.PUBLIC_URL}/password/change`,
        Component: <ChangePasswordForm />,
        permission: 'MANAGE_SYSTEM',
    },
    // {
    //     path: `${process.env.PUBLIC_URL}/password/forgot`,
    //     Component: <ForgotPasswordForm />,
    //     permission: 'MANAGE_SYSTEM',
    // },
    {
        path: `${process.env.PUBLIC_URL}/password/reset`,
        Component: <ResetPasswordForm />,
        permission: 'MANAGE_SYSTEM',
    },
    // Departments
    {
        path: `${process.env.PUBLIC_URL}/departments/create`,
        Component: <DepartmentForm />,
        permission: 'MODIFY_DEPARTMENTS',
    },
    {
        path: `${process.env.PUBLIC_URL}/departments`,
        Component: <DepartmentTable />,
        permission: 'ACCESS_DEPARTMENTS',
    },
    {
        path: `${process.env.PUBLIC_URL}/departments/edit/:id`,
        Component: <DepartmentForm />,
        permission: 'MODIFY_DEPARTMENTS',
    },
    // Profile (Accessing profile does not require any specific permission)
    {
        path: `${process.env.PUBLIC_URL}/profile`,
        Component: <Profile />,
    },
];
