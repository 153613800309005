import React, { useCallback, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Button, Input, Card, CardBody } from 'reactstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Breadcrumbs } from 'AbstractElements';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import CommonModal from 'Components/UiKits/Modals/common/modal';
import LaboratoryService from 'Services/LaboratoryService';
import PaginationComponent from 'Components/Pagination/PaginationComponent';

const LaboratoryTable = () => {
    const { t } = useTranslation(['general', 'laboratory']);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedLaboratoryId, setSelectedLaboratoryId] = useState(null);
    const [filterText, setFilterText] = useState('');
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    useEffect(() => {
        debouncedFetchLaboratories(currentPage, pageSize, filterText);
        return () => {
            debouncedFetchLaboratories.cancel();
        };
    }, [currentPage, pageSize, filterText]);

    const fetchLaboratories = async (page = 0, size = 10, filterText) => {
        try {
            const response = await LaboratoryService.getLaboratories(page, size, filterText);
            setData(response.content);
            setTotalRecords(response.totalElements);
        } catch (error) {
            toast.error(t('laboratory:FetchDataLaboratoryE'));
        }
    };

    const debouncedFetchLaboratories = useCallback(
        debounce((page, size, filterText) => {
            fetchLaboratories(page, size, filterText);
        }, 300),
        [],
    );

    const handleDelete = (id) => {
        setSelectedLaboratoryId(id);
        setModal(true);
    };

    const handleEdit = (id) => {
        navigate(`/laboratories/edit/${id}`);
    };

    const confirmDelete = async () => {
        try {
            await LaboratoryService.deleteLaboratory(selectedLaboratoryId);
            setData(data.filter((lab) => lab.id !== selectedLaboratoryId));
            setModal(false);
            toast.success(t('laboratory:DeleteLaboratoryS'));
        } catch (error) {
            toast.error(t('laboratory:DeleteLaboratoryE'));
        }
    };

    const openAddForm = () => {
        navigate('/laboratories/create');
    };

    const toggleModal = () => setModal(!modal);
    const handleRowClick = (row) => {
        console.log('Laboratory Selected:', {
            laboratoryId: row.id,
            laboratoryName: row.name,
            address: row.address,
            province: row.province,
            district: row.district,
            partnerId: row.partnerId,
            personInChargeName: row.personInChargeName,
        });
    };

    const laboratoryTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            width: '100px',
        },
        {
            name: t('laboratory:LaboratoryName'),
            selector: (row) => row.name,
            sortable: true,
            cell: (row) => <div style={{ textAlign: 'left', marginLeft: '5px' }}>{row.name}</div>,
        },
        {
            name: t('general:Address'),
            selector: (row) => row.address,
            sortable: true,
            center: true,
        },
        {
            name: t('general:Province/City'),
            selector: (row) => row.province,
            sortable: true,
        },
        {
            name: t('general:District'),
            selector: (row) => row.district,
            sortable: true,
        },
        {
            name: t('general:Action'),
            cell: (row) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FaEdit
                        style={{
                            cursor: 'pointer',
                            marginRight: '10px',
                            color: '#007bff',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleEdit(row.id)}
                    />
                    <FaTrash
                        style={{
                            cursor: 'pointer',
                            color: '#dc3545',
                            fontSize: '1.2rem',
                        }}
                        onClick={() => handleDelete(row.id)}
                    />
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#f2f1fd',
            },
        },
    };

    return (
        <>
            <Breadcrumbs
                mainTitle={t('laboratory:ManageLaboratories')}
                parent={{ label: t('laboratory:ManageLaboratories'), url: '/laboratories' }}
            />
            <Container fluid={true}>
                <Card>
                    <CardBody>
                        <Row className="align-items-center mb-3">
                            <Col md="4">
                                <h4>{t('laboratory:Laboratories')}</h4>
                            </Col>
                            <Col md="3" className="text-end">
                                <Button className="txt-primary" color="primary" onClick={openAddForm}>
                                    + {t('laboratory:BtnAddLaboratory')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col md="6">
                                <label>{t('general:RowsPerPage')}</label>
                                <Input
                                    type="select"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(Number(e.target.value))}
                                    className="w-auto d-inline-block ms-2"
                                >
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </Input>
                            </Col>
                            <Col md="6" className="text-end">
                                <Input
                                    type="text"
                                    placeholder={t('general:EnterNameOrAddress')}
                                    className="w-auto d-inline-block"
                                    name="search"
                                    id="searchInput"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                                <Button color="primary" className="ms-2 txt-primary">
                                    {t('general:Search')}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                        <DataTable
                            data={data}
                            columns={laboratoryTableColumns}
                            striped={true}
                            center={true}
                            customStyles={customStyles}
                            onRowClicked={handleRowClick}
                        />
                        <Row className="mt-4 justify-content-end">
                            <Col md="auto" className="d-flex align-items-center">
                                <div className="me-3">
                                    {t('general:Total')} {totalRecords} {t('general:Items')}
                                </div>
                                <PaginationComponent
                                    totalRecords={totalRecords}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>

            <ToastContainer />

            <CommonModal
                modal={modal}
                title={t('laboratory:DeleteLaboratory')}
                content={t('laboratory:DeleteConfirmation')}
                toggle={toggleModal}
                onConfirm={confirmDelete}
            />
        </>
    );
};

export default LaboratoryTable;
