// frontend/src/Route/index.jsx
import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Loader from '../Layout/Loader';
import RouteHandler from './RouteHandler';
import { publicRoutes } from './publicRoutes';

const Routers = () => {
    return (
        <BrowserRouter basename={'/'}>
            <Suspense fallback={<Loader />}>
                <Routes>
                    <Route path={'/'} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/`} />} />
                    <Route path={`/*`} element={<RouteHandler />} />
                    {publicRoutes.map(({ path, Component }, i) => (
                        <Route key={i} path={path} element={Component} />
                    ))}
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
};

export default Routers;
