import { Fragment } from 'react';
import ChemicalsTable from './ChemicalsTable';
import { Breadcrumbs } from 'AbstractElements';
const Chemicals = () => {
    return (
        <Fragment>
            <Breadcrumbs parent="Quản lý hóa chất" title="Danh sách" mainTitle="Quản lý hóa chất" />
            <ChemicalsTable />
        </Fragment>
    );
};
export default Chemicals;
