export const notificationSliderOption = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    vertical: true,
    variableWidth: false,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
};
