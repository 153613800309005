import React, { Fragment, useEffect, useState } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    Spinner,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import LaboratoryService from 'Services/LaboratoryService';
import { Breadcrumbs } from 'AbstractElements';
import ProvinceDistrictSelect from 'CommonElements/ProvinceDistrictSelection/ProvinceDistrictSelection';
import UserService from 'Services/UserService'; // Assuming you have a service to fetch users

const LaboratoryForm = () => {
    const { t } = useTranslation(['general', 'laboratory']);
    const [laboratory, setLaboratory] = useState({
        name: '',
        address: '',
        province: '',
        district: '',
        // ward: '',
        personInChargeId: null, // Added field for person in charge
    });

    const [users, setUsers] = useState([]); // Users for dropdown selection
    const [initialLaboratory, setInitialLaboratory] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const isEditMode = Boolean(id);

    const [selectedProvince, setSelectedProvince] = useState(null);
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [selectedWard, setSelectedWard] = useState(null);
    useEffect(() => {
        const fetchLaboratory = async () => {
            if (isEditMode) {
                try {
                    const laboratoryData = await LaboratoryService.getInforLaboratory(id);
                    setLaboratory({
                        ...laboratoryData,
                        personInChargeId: laboratoryData.personInChargeId, // Ensure personInChargeId is set
                    });
                    setInitialLaboratory(laboratoryData);
                    setSelectedProvince({ value: laboratoryData.province, label: laboratoryData.province });
                    setSelectedDistrict({ value: laboratoryData.district, label: laboratoryData.district });
                } catch (error) {
                    toast.error(t('laboratory:FetchDataLaboratoryE'));
                }
            }
        };

        // Fetch users for the person in charge dropdown
        const fetchUsers = async () => {
            try {
                const usersData = await UserService.getAllUsers();
                setUsers(usersData);
            } catch (error) {
                toast.error(t('laboratory:ErrorFetchingUsers'));
            }
        };

        fetchLaboratory();
        fetchUsers(); // Fetch users when component mounts
    }, [id, isEditMode, t]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLaboratory({ ...laboratory, [name]: value });
    };

    const handleProvinceChange = (selectedOption) => {
        setSelectedProvince(selectedOption);
        setLaboratory({ ...laboratory, province: selectedOption ? selectedOption.label : '' });
        setSelectedDistrict(null);
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistrict(selectedOption);
        setLaboratory({ ...laboratory, district: selectedOption ? selectedOption.label : '' });
        setSelectedWard(null);
    };
    const handleWardChange = (selectedOption) => {
        setSelectedWard(selectedOption);
        // setLaboratory({ ...laboratory, ward: selectedOption ? selectedOption.label : '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!laboratory.personInChargeId) {
            toast.error(t('laboratory:SelectUser'));
            setLoading(false);
            return;
        }

        try {
            // Loại bỏ personInChargeName trước khi gửi dữ liệu
            const { personInChargeName, ...laboratoryWithoutPersonInChargeName } = laboratory;

            if (isEditMode) {
                const { id, ...laboratoryWithoutIdAndName } = laboratoryWithoutPersonInChargeName;
                await LaboratoryService.updateLaboratory(id, laboratoryWithoutIdAndName);
                toast.success(t('laboratory:EditLaboratoryS'));
            } else {
                await LaboratoryService.createLaboratory(laboratoryWithoutPersonInChargeName);
                toast.success(t('laboratory:AddLaboratoryS'));
            }
            navigate('/laboratories/');
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
                if (isEditMode && initialLaboratory) {
                    setLaboratory(initialLaboratory);
                }
            } else {
                toast.error(t('laboratory:SaveLaboratoryE'));
            }
        } finally {
            setLoading(false);
        }
    };

    const handleReset = () => {
        navigate('/laboratories/');
    };

    return (
        <>
            <Breadcrumbs
                mainTitle={isEditMode ? t('laboratory:EditLaboratory') : t('laboratory:AddLaboratory')}
                parent={{ label: t('laboratory:ManageLaboratories'), url: '/laboratories' }}
                title={isEditMode ? t('laboratory:EditLaboratory') : t('laboratory:AddLaboratory')}
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader style={{ background: '#f2f1fd' }}>
                                <h5>{t('DetailInformation')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Form className="theme-form" onSubmit={handleSubmit}>
                                    {/* Laboratory Name */}
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('laboratory:LaboratoryName')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('laboratory:LaboratoryName')}
                                                name="name"
                                                value={laboratory.name}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    {/* Address */}
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('general:Address')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="text"
                                                placeholder={t('general:Address')}
                                                name="address"
                                                value={laboratory.address}
                                                onChange={handleChange}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>

                                    {/* Province and District Select */}
                                    <ProvinceDistrictSelect
                                        selectedProvince={selectedProvince}
                                        setSelectedProvince={handleProvinceChange}
                                        selectedDistrict={selectedDistrict}
                                        setSelectedDistrict={handleDistrictChange}
                                        selectedWard={selectedWard}
                                        setSelectedWard={handleWardChange}
                                    />

                                    {/* Person In Charge */}
                                    <FormGroup className="row">
                                        <Label className="col-sm-3 col-form-label">
                                            {t('laboratory:PersonInChargeName')}
                                            <span className="text-danger">*</span>
                                        </Label>
                                        <Col sm="9">
                                            <Input
                                                className="form-control"
                                                type="select"
                                                name="personInChargeId"
                                                value={laboratory.personInChargeId || ''}
                                                onChange={handleChange}
                                                required
                                            >
                                                <option value="">{t('laboratory:SelectUser')}</option>
                                                {users.map((user) => (
                                                    <option key={user.userId} value={user.userId}>
                                                        {user.fullName}
                                                    </option>
                                                ))}
                                            </Input>
                                        </Col>
                                    </FormGroup>

                                    {/* Form Footer with Buttons */}
                                    <CardFooter className="text-start">
                                        <Button
                                            color="primary"
                                            className="m-r-15 txt-primary"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading
                                                ? t('general:Processing')
                                                : isEditMode
                                                ? t('general:BtnEdit')
                                                : t('general:BtnAdd')}
                                        </Button>
                                        <Button color="outline-secondary" type="reset" onClick={handleReset}>
                                            {t('general:Cancel')}
                                        </Button>
                                    </CardFooter>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LaboratoryForm;
