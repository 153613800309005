export const MENUITEMS = [
    {
        menutitle: 'GeneralManagement_label',
        menucontent: 'Manage, All',
        Items: [
            {
                title: 'Doctors_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_DOCTORS',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/doctors`,
                        title: 'DoctorList_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Customers_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_CUSTOMERS',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/customers`,
                        title: 'CustomerList_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Packages_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_PACKAGES',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/packages`,
                        title: 'PackageList_label',
                        type: 'link',
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/package-categories`,
                        title: 'CategoryList_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Partners_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_PARTNERS',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/partners`,
                        title: 'PartnerList_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Departments_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_DEPARTMENTS',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/departments`,
                        title: 'DepartmentList_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Samples_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_SAMPLES',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/samples`,
                        title: 'SampleList_label',
                        type: 'link',
                    },
                    {
                        path: `${process.env.PUBLIC_URL}/samples2`,
                        title: 'SampleList_label2',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Laboratories_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'ACCESS_LABORATORIES',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/laboratories`,
                        title: 'LaboratoryList_label',
                        type: 'link',
                    },
                ],
            },
        ],
    },
    {
        menutitle: 'SystemManagement_label',
        menucontent: 'System',
        Items: [
            {
                title: 'TestProcess_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'MANAGE_SYSTEM',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/flow`,
                        title: 'AddNew_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Users_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'MANAGE_SYSTEM',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/users`,
                        title: 'UserList_label',
                        type: 'link',
                    },
                ],
            },
            {
                title: 'Roles_label',
                icon: 'widget',
                type: 'sub',
                active: false,
                permission: 'MANAGE_SYSTEM',
                children: [
                    {
                        path: `${process.env.PUBLIC_URL}/roles`,
                        title: 'RoleList_label',
                        type: 'link',
                    },
                ],
            },
        ],
    },
];
