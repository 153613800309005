import { Btn } from 'AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { Card, CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label } from 'reactstrap';
import AuthService from 'Services/AuthService';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const ResetPasswordForm = () => {
    const [formValues, setFormValues] = useState({
        password: '',
        confirmPassword: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const query = useQuery();
    const [token, setToken] = useState('');

    useEffect(() => {
        const extractedToken = query.get('token');
        setToken(extractedToken);
    }, [query]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const handleReset = () => {
        setFormValues({
            password: '',
            confirmPassword: '',
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { password, confirmPassword } = formValues;

        if (password !== confirmPassword) {
            setError('Mật khẩu mới và xác nhận mật khẩu không khớp.');
            return;
        }

        setLoading(true);
        setError(null);

        try {
            await AuthService.resetPassword({ token, ...formValues });

            setLoading(false);
            handleReset();
            toast.success('Thay đổi mật khẩu thành công');
        } catch (err) {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            <Card>
                <CardHeader>
                    <h5>Reset mật khẩu</h5>
                </CardHeader>
                <CardBody>
                    {error && <div className="alert alert-danger">{error}</div>}
                    <Form className="theme-form" onSubmit={handleSubmit}>
                        <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">Mật khẩu mới</Label>
                            <Col sm="9">
                                <Input
                                    className="form-control"
                                    type="password"
                                    placeholder="Mật khẩu mới"
                                    name="password"
                                    value={formValues.password}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Label className="col-sm-3 col-form-label">Xác nhận mật khẩu mới</Label>
                            <Col sm="9">
                                <Input
                                    className="form-control"
                                    type="password"
                                    placeholder="Xác nhận mật khẩu mới"
                                    name="confirmPassword"
                                    value={formValues.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <CardFooter className="text-start">
                            <Btn
                                attrBtn={{
                                    color: 'primary',
                                    className: 'm-r-15',
                                    type: 'submit',
                                    disabled: loading,
                                }}
                            >
                                Xác nhận
                            </Btn>
                        </CardFooter>
                    </Form>
                </CardBody>
            </Card>
        </Fragment>
    );
};

export default ResetPasswordForm;
