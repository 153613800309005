import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Import JSON files
import generalEn from 'assets/i18n/en/general.json';
import roleEn from 'assets/i18n/en/role.json';
import userEn from 'assets/i18n/en/user.json';
import doctorEn from 'assets/i18n/en/doctor.json';
import customerEn from 'assets/i18n/en/customer.json';
import partnerEn from 'assets/i18n/en/partner.json';
import laboratoryEn from 'assets/i18n/en/laboratory.json';
import packEn from 'assets/i18n/en/pack.json';
import packCateEn from 'assets/i18n/en/packCate.json';
import departmentEn from 'assets/i18n/en/department.json';
import layoutEn from 'assets/i18n/en/layout.json';
import errorPageEn from 'assets/i18n/en/errorPage.json';
import statusEn from 'assets/i18n/en/status.json';
import sampleEn from 'assets/i18n/en/sample.json';

import generalVn from 'assets/i18n/vn/general.json';
import roleVn from 'assets/i18n/vn/role.json';
import userVn from 'assets/i18n/vn/user.json';
import doctorVn from 'assets/i18n/vn/doctor.json';
import customerVn from 'assets/i18n/vn/customer.json';
import partnerVn from 'assets/i18n/vn/partner.json';
import laboratoryVn from 'assets/i18n/vn/laboratory.json';
import packVn from 'assets/i18n/vn/pack.json';
import packCateVn from 'assets/i18n/vn/packCate.json';
import departmentVn from 'assets/i18n/vn/department.json';
import layoutVn from 'assets/i18n/vn/layout.json';
import errorPageVn from 'assets/i18n/vn/errorPage.json';
import statusVn from 'assets/i18n/vn/status.json';
import sampleVn from 'assets/i18n/vn/sample.json';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                general: generalEn,
                role: roleEn,
                user: userEn,
                doctor: doctorEn,
                customer: customerEn,
                partner: partnerEn,
                laboratory: laboratoryEn,
                pack: packEn,
                packCate: packCateEn,
                department: departmentEn,
                layout: layoutEn,
                errorPage: errorPageEn,
                status: statusEn,
                sample: sampleEn,
            },
            vn: {
                general: generalVn,
                role: roleVn,
                user: userVn,
                doctor: doctorVn,
                customer: customerVn,
                partner: partnerVn,
                laboratory: laboratoryVn,
                pack: packVn,
                packCate: packCateVn,
                department: departmentVn,
                layout: layoutVn,
                errorPage: errorPageVn,
                status: statusVn,
                sample: sampleVn,
            },
        },
        fallbackLng: 'vn',
        lng: 'vn',
        debug: false,
        ns: [
            'general',
            'role',
            'user',
            'doctor',
            'customer',
            'partner',
            'laboratory',
            'pack',
            'packCate',
            'department',
            'layout',
            'status',
            'sample',
        ],
        defaultNS: 'general',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
