import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P } from 'AbstractElements';
import { useNavigate } from 'react-router-dom';
import CustomizerContext from '_helper/Customizer';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { login } from 'redux/slice/authSlice';
import AuthService from 'Services/AuthService';
import { useTranslation } from 'react-i18next';

const Signin = () => {
    const { t } = useTranslation(['user', 'general']);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [togglePassword, setTogglePassword] = useState(false);
    const history = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        const checkTokenAndRedirect = async () => {
            const token = AuthService.getToken();
            if (token) {
                const isValid = await AuthService.introspectToken(token);
                if (isValid) {
                    history(`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`);
                } else {
                    await AuthService.logout();
                    AuthService.removeToken();
                }
            }
        };

        checkTokenAndRedirect();
    }, [history, layoutURL]);

    const loginAuth = async (e) => {
        e.preventDefault();
        try {
            await dispatch(login({ email, password })).unwrap(); // Use email and password
            toast.success(t('user:LoginSuccess')); // Use translation for success message
            history(`${process.env.PUBLIC_URL}/dashboard/${layoutURL}`);
        } catch (error) {
            console.log(error);
            toast.error(t('user:InvalidCredentials')); // Use translation for error message
        }
    };

    return (
        <Fragment>
            <Container fluid={true} className="p-0 login-page">
                <Row>
                    <Col xs="12">
                        <div className="login-card">
                            <div className="login-main login-tab">
                                <Form className="theme-form" onSubmit={loginAuth}>
                                    <H4>{t('user:AdminLogin')}</H4> {/* Translation for Admin Login */}
                                    <P>{t('user:EnterEmailPassword')}</P> {/* Translation for Enter Email & Password */}
                                    <FormGroup>
                                        <Label className="col-form-label">{t('user:Email')}</Label>{' '}
                                        {/* Translation for Email */}
                                        <Input
                                            className="form-control"
                                            type="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                            required={true}
                                        />
                                    </FormGroup>
                                    <FormGroup className="position-relative">
                                        <Label className="col-form-label">{t('user:Password')}</Label>{' '}
                                        {/* Translation for Password */}
                                        <div className="position-relative">
                                            <Input
                                                className="form-control"
                                                type={togglePassword ? 'text' : 'password'}
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                            />
                                            <div
                                                className="show-hide"
                                                onClick={() => setTogglePassword(!togglePassword)}
                                            >
                                                <span className={togglePassword ? '' : 'show'}></span>
                                            </div>
                                        </div>
                                    </FormGroup>
                                    <div className="position-relative form-group mb-0">
                                        <Btn
                                            attrBtn={{
                                                color: 'primary',
                                                className: 'd-block w-100 mt-2 txt-primary',
                                                type: 'submit',
                                            }}
                                        >
                                            {t('user:SignIn')} {/* Translation for Sign In */}
                                        </Btn>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default Signin;
