import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../../../AbstractElements';
import { Close, SaveChanges } from '../../../../Constant';

const CommonModal = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggler} size={props.size} centered>
            <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
            <ModalBody className={props.bodyClass}>{props.children}</ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.closeText}>
                    {props.closeText || Close}
                </Button>
                <Button className="txt-primary" color="primary" onClick={props.action}>
                    {props.saveText || SaveChanges}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default CommonModal;
