import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const View2 = ({ sample }) => {
    console.log(sample);

    return (
        <Container fluid={true} style={{ padding: '20px', backgroundColor: '#f8f9fa', borderRadius: '8px' }}>
            <Row className="mb-3">
                <Row className="align-items-center mb-3">
                    <Col md="12">
                        <h4>This is advanced cardio check view</h4>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>ID:</span>
                            <span style={styles.value}>{sample?.id}</span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Barcode:</span>
                            <span style={styles.value}>{sample?.barcode}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Loại xét nghiệm:</span>
                            <span style={styles.value}>{sample?.pack?.name}</span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Collection Date:</span>
                            <span style={styles.value}>{sample?.collectionDate}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Customer Name:</span>
                            <span style={styles.value}>{sample?.customer?.name}</span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Sample Type:</span>
                            <span style={styles.value}>{sample?.sampleType}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Sampling Date:</span>
                            <span style={styles.value}>{sample?.samplingDate}</span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Status Statement:</span>
                            <span style={styles.value}>{sample?.statusStatement}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Tube Type:</span>
                            <span style={styles.value}>{sample?.tubeType}</span>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div style={styles.row}>
                            <span style={styles.label}>Location:</span>
                            <span style={styles.value}>{sample?.location}</span>
                        </div>
                    </Col>
                </Row>
            </Row>
        </Container>
    );
};

const styles = {
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '10px 0',
    },
    label: {
        fontWeight: 'bold',
        color: '#495057',
        fontSize: '1rem',
        textAlign: 'left',
        flex: 1,
    },
    value: {
        color: '#343a40',
        fontSize: '1rem',
        textAlign: 'left',
        flex: 1,
    },
};

export default View2;
